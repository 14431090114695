import { Fragment, useEffect, useRef, useState } from "react";
import { Icon, Button, Text } from "components/atoms";
import CAwhite from "assets/logo/casa_andina_white.png";
import CAblack from "assets/logo/casa_andina_black.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "hooks/useAppSelector";
import "./styles.scss";
import { shallowEqual } from "react-redux";
import { setCoin, setLanguage } from "redux/actions/config";
import { getCurrencies } from "actions/currencies/services";
import { useTranslation } from "react-i18next";
import { getBoxSearch } from "actions/boxsearch/services";
import Searchboxgeneral from "./searchboxgeneral";
import { handlePhoneCall, redirectUrlExternal } from "utils/function";
import { getMenuOferta } from "actions/menu/services";
import { SUPPORT_LANG } from "utils/valuesForms";

const Navbar = () => {
  const { city } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolledButton, setIsScrolledButton] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const { currencies, client_info, agency, hotelData, language, coin } = useAppSelector(state => ({
    currencies: state.language.dataCurrencies?.divisas || [],
    client_info: state.language.dataCurrencies?.client_info?.currency_code,
    agency: state.language.dataAgency || null,
    hotelData: state.language.dataHotelesDestination || [],
    language: state.config.language,
    coin: state.config.coin,
  }))

  const allowedRoutes = [`/${i18n.language}/${t("routes.destinations")}`, `/${i18n.language}/${t("routes.hotels")}`];
  const isRoute = allowedRoutes.includes(location.pathname) || new URLSearchParams(location.search).has('nav');
  const isChangeHotel = location.pathname.startsWith(
    `/${i18n.language}/${t("routes.destinations")}/${city}/${t("routes.hotels")}`
  );

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelectCurrency = (event: any) => {
    const selectedCurrency = event.target.value;
    setCoin(selectedCurrency);
    setIsOpen(false);
    localStorage && localStorage.setItem('selectedCurrency', selectedCurrency);
  };

  const home = () => {
    navigate(`/${i18n.language}/home`);
  };

  const handleRedirect = () => {
    handleToggle();
    home();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = e.target.value;
    setIsOpen(false);
    changeLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage && localStorage.setItem("i18nextLng", selectedLanguage);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).then(() => {
      localStorage && localStorage.setItem("i18nextLng", lng);
      const newPath = `/${lng}${getPathWithTranslatedRoute(location.pathname, lng)}`;
      navigate(newPath, { replace: true });
    });
  };

  const navigateHotelsView = () => {
    handleMouseLeaveDestinations();
    setIsOpen(false)
    navigate(`/${i18n.language}/${t('nav.hotelsUrl')}`);
  };

  const getPathWithTranslatedRoute = (pathname: string, lng: string) => {
    const parts = pathname.split("/");
    let translatedRoute = "";

    // Determinar si el primer segmento es un slug de idioma
    let startIndex = 1;
    if (parts[1] === "es" || parts[1] === "en" || parts[1] === "pt") {
      startIndex = 2;
    }

    // Mapear las partes de la ruta a sus traducciones correspondientes
    for (let i = startIndex; i < parts.length; i++) {
      const part = parts[i];

      switch (part) {
        case "home":
          translatedRoute += i18n.t("routes.home", { lng });
          break;
        case "destinos":
        case "destinations":
        case "destinacoes":
          translatedRoute += i18n.t("routes.destinations", { lng });
          break;
        case "restaurantes":
        case "restaurants":
          translatedRoute += i18n.t("routes.restaurants", { lng });
          break;
        case "hoteles":
        case "hotels":
        case "hoteis":
          translatedRoute += i18n.t("routes.hotels", { lng });
          break;
        case "ofertas":
        case "offers":
          translatedRoute += i18n.t("routes.offers", { lng });
          break;
        case "better-price":
        case "mejores-precios":
        case "melhores-precos":
          translatedRoute += i18n.t("routes.better_price", { lng });
          break;
        case "eventos":
        case "events":
          translatedRoute += i18n.t("routes.events", { lng });
          break;
        case "responsabilidad-social":
        case "social-responsability":
        case "responsabilidade-social":
          translatedRoute += i18n.t("routes.social_responsability", { lng });
          break;
        case "reservations-agents":
        case "reservas-agentes":
        case "agentes-de-reserva":
          translatedRoute += i18n.t("routes.reservations-agents", { lng });
          break;
        case "terminos-y-condiciones":
        case "terms-and-conditions":
        case "termos-e-condicoes":
          translatedRoute += i18n.t("routes.terms-and-conditions", { lng });
          break;
        case "web-privacy-policy":
        case "politica-web-de-privacidad":
        case "politica-web-de-privacidade":
          translatedRoute += i18n.t("routes.web-privacy-policy", { lng });
          break;
        case "personal-data-protection-policy":
        case "politica-de-proteccion-de-datos-personales":
        case "politica-de-protecao-de-dados-pessoais":
          translatedRoute += i18n.t("routes.personal-data-protection-policy", {
            lng,
          });
          break;
        case "terms-and-conditions-for-bookings-at-associated-hotels":
        case "terminos-y-condiciones-de-reservas-en-hoteles-asociados":
        case "termos-e-condicoes-de-reservas-em-hoteis-associados":
          translatedRoute += i18n.t(
            "routes.terms-and-conditions-for-bookings-at-associated-hotels",
            { lng }
          );
          break;
        default:
          translatedRoute += part; // Si no es una palabra clave, mantener la parte original
      }

      // Agregar una barra inclinada si no es la última parte de la ruta
      if (i < parts.length - 1) {
        translatedRoute += "/";
      }
    }

    return `/${translatedRoute}`;
  };

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const lang = pathParts[1];

    if (SUPPORT_LANG.includes(lang) && lang !== language) {
      setLanguage(lang);
      localStorage && localStorage.setItem("i18nextLng", lang);
    }
  }, [location.pathname, language]);

  const [activeTab, setActiveTab] = useState("destinos");
  const [selectedDestination, setSelectedDestination] = useState<number | null>(
    null
  );

  const handleDestinationClick = (index: number) => {
    setSelectedDestination(index);

    const destinosList = document.querySelector(".destinos-list");

    if (destinosList) {
      if (index !== null) {
        destinosList.classList.add("single-column");
      } else {
        destinosList.classList.remove("single-column");
      }
    }
  };

  const handleCloseClick = () => {
    setSelectedDestination(null);

    const destinosList = document.querySelector(".destinos-list");
    if (destinosList) {
      destinosList.classList.remove("single-column");
    }
  };

  const navHotel = () => {
    return (
      <div className="d-flex jc-around ai-center g-1rem tc-secondary-color2">
        <Text type="paragraph" text={hotelData?.titulo} />
        {isScrolledButton && (
          <Button type="gold" onClick={() => window.scrollTo(0, 0)}>
            {t("general.book")}
          </Button>
        )}
      </div>
    );
  };

  const navMobSandwich = () => {
    return (
      <div
        className={`navmobile ${isOpen ? "open" : "close"} ${
          isScrolled ? "scrolled" : ""
        }`}
        ref={containerRef}
      >
        <div className="navmobile__container">
          {isOpen ? (
            <>
              {/* <div
                  className="navmobile__container--overlay"
                  onClick={handleToggle}
                /> */}
              <div className="navmobile__container--modal">
                <div className="sidem">
                  <div className="sidem__modal">
                    <div className="closes">
                      <div className="closes__content">
                        <Icon
                          name="sandwich-gold"
                          list="items"
                          width={30}
                          height={30}
                          onClick={handleToggle}
                        />
                        <div className="cursor" onClick={handleRedirect}>
                          <img src={CAblack} alt="Logo" width={133} />
                        </div>
                      </div>
                      <Icon
                        name="close"
                        list="items"
                        width={24}
                        height={24}
                        onClick={handleToggle}
                      />
                    </div>
                    <div className="items">
                      <div className="items__life">
                        <div className="items__life--container">
                          <Icon
                            name="user2"
                            list="items"
                            height={24}
                            width={24}
                            className="cursor"
                          />
                          <div className="items__life--text">
                            <p className="title">Casa Andina Life</p>
                            <div style={{ display: "flex", gap: "6px" }}>
                              <p
                                className="subtitle"
                                onClick={() =>
                                  redirectUrlExternal(
                                    "https://life.casa-andina.com/login"
                                  )
                                }
                              >
                                {t(`nav.login`)}
                              </p>
                              <hr color="#D69C4F" />
                              <p
                                className="subtitle"
                                onClick={() =>
                                  redirectUrlExternal(
                                    "https://life.casa-andina.com/register"
                                  )
                                }
                              >
                                {t(`nav.signup`)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="items__links">
                        <div className="options">
                          <select
                            onChange={handleSelectCurrency}
                            className="select"
                            value={coin}
                          >
                            {currencies.map((currency: any) => (
                              <option value={currency.moneda} key={currency.id}>
                                {currency.moneda}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="options">
                          <Icon
                            name="world"
                            list="items"
                            height={20}
                            width={20}
                            className="cursor"
                          />
                          <select
                            value={i18n.language}
                            onChange={handleChangeLanguage}
                          >
                            {Object.keys(i18n.options?.resources || {}).map(
                              (lng) => (
                                <option key={lng} value={lng}>
                                  {t(`languages.${lng}`)}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <div>
                          <Icon
                            name="phone"
                            list="items"
                            height={20}
                            width={20}
                            className="phone"
                            onClick={() => handlePhoneCall("+5113916500")}
                          />
                        </div>
                      </div>
                      <div className="items__pages">
                        {dataMenu &&
                          dataMenu.urls_navbar &&
                          dataMenu?.urls_navbar[0]?.titulo && (
                            <div className="dropdown">
                              <div
                                className="dropdown-header"
                                onClick={() => {
                                  navigate(
                                    dataMenu?.urls_navbar[0]?.redireccion
                                  );
                                  setIsOpen(false);
                                }}
                                style={{
                                  color: "var(--color-gold)",
                                  fontWeight: 500,
                                }}
                              >
                                <p>{dataMenu?.urls_navbar[0]?.titulo}</p>
                              </div>
                            </div>
                          )}
                        <div className="dropdown">
                          <div
                            className="dropdown-header"
                            onClick={() => {
                              navigate(
                                `/${i18n.language}/${t(
                                  "routes.reservations-agents"
                                )}`
                              );
                              setIsOpen(false);
                            }}
                          >
                            <p>{t("nav.agencies")}</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <div
                            className="dropdown-header"
                            onClick={toggleDropdownItem}
                          >
                            <p style={{ fontWeight: isOpenItem ? 600 : 400 }}>
                              {t("nav.destinations")}
                            </p>

                            <Icon
                              name={`${isOpenItem ? "up2" : "down2"}`}
                              list="arrows"
                              height={12}
                            />
                          </div>
                          {isOpenItem && (
                            <div className="dropdown-content">
                              <div
                                className="navbar-item normal navbar-item-destacado our-hotels-button"
                                onClick={navigateHotelsView}
                              >
                                {t("nav.seeOurHotels")}
                              </div>
                            </div>
                          )}
                          {isOpenItem &&
                            dataMenu?.destinos.map(
                              (data: any, index: number) => (
                                <Fragment key={index}>
                                  <div
                                    className="dropdown-content"
                                    onClick={() => toggleDropdownItems(index)}
                                  >
                                    <div className="dropdown-text">
                                      <p
                                        style={{
                                          fontWeight: openDropdowns[index]
                                            ? "600"
                                            : "400",
                                        }}
                                      >
                                        {data.titulo}
                                      </p>
                                    </div>
                                    <div className="dropdown-subtext">
                                      <p
                                        style={{
                                          fontWeight: openDropdowns[index]
                                            ? "600"
                                            : "400",
                                        }}
                                      >
                                        ({data.num_hoteles})
                                      </p>
                                    </div>
                                    <Icon
                                      name={`${
                                        openDropdowns[index] ? "up2" : "down2"
                                      }`}
                                      list="arrows"
                                      height={12}
                                    />
                                  </div>
                                  {openDropdowns[index] && (
                                    <Fragment>
                                      <div
                                        className="dropdown-hotel"
                                        style={{
                                          paddingLeft: "16px",
                                        }}
                                        onClick={() => {
                                          navigate(data.redireccion);
                                          setIsOpen(false);
                                          setOpenDropdowns({
                                            [index]: false,
                                          });
                                          setIsOpenItem(false);
                                          window.scrollTo(0, 0);
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: "#B3B3B3",
                                            fontSize: "10px",
                                          }}
                                        >
                                          {t(`nav.goToDestination`)}
                                        </p>
                                        <p>{data.titulo}</p>
                                      </div>
                                      <div className="dropdown-hotel2">
                                        <p
                                          style={{
                                            marginTop: "14px",
                                            color: "#B3B3B3",
                                            fontSize: "10px",
                                            paddingLeft: "16px",
                                          }}
                                        >
                                          {t(`nav.selectAHotel`)}
                                        </p>
                                        {data.hoteles.map(
                                          (item: any, index: number) => (
                                            <div
                                              key={index}
                                              className="dropdown-hotel"
                                              style={{
                                                borderTop: 0,
                                                paddingBlock: 10,
                                                marginLeft: "0",
                                                paddingLeft: "16px",
                                              }}
                                              onClick={() => {
                                                navigate(item.redireccion);
                                                setIsOpen(false);
                                                setOpenDropdowns({
                                                  [index]: false,
                                                });
                                                setIsOpenItem(false);
                                                window.scrollTo(0, 0);
                                              }}
                                            >
                                              <p>{item.titulo}</p>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </Fragment>
                                  )}
                                </Fragment>
                              )
                            )}
                        </div>
                        <div className="dropdown">
                          <div
                            className="dropdown-header"
                            onClick={toggleDropdownOfertas}
                          >
                            <p
                              style={{
                                fontWeight: isOpenItemOfertas ? 600 : 400,
                              }}
                            >
                              {t("nav.offers")}
                            </p>

                            <Icon
                              name={`${isOpenItemOfertas ? "up2" : "down2"}`}
                              list="arrows"
                              height={12}
                            />
                          </div>
                          {isOpenItemOfertas &&
                            dataMenu?.ofertas.map(
                              (data: any, index: number) => (
                                <Fragment key={index}>
                                  <div
                                    className="dropdown-content"
                                    onClick={() => toggleDropdownItems(index)}
                                  >
                                    <div className="dropdown-text">
                                      <p>{data.titulo}</p>
                                    </div>

                                    <Icon
                                      name={`${
                                        openDropdowns[index] ? "up2" : "down2"
                                      }`}
                                      list="arrows"
                                      height={12}
                                    />
                                  </div>
                                  {openDropdowns[index] &&
                                    data.promociones.map(
                                      (item: any, index: number) => (
                                        <div
                                          key={index}
                                          className="dropdown-hotel"
                                          style={{
                                            paddingLeft: "16px",
                                          }}
                                          onClick={() => {
                                            navigate(item.redireccion);
                                            setIsOpen(false);
                                            setOpenDropdowns({
                                              [index]: false,
                                            });
                                            setIsOpenItemOfertas(false);
                                            window.scrollTo(0, 0);
                                          }}
                                        >
                                          <p>{item.titulo}</p>
                                        </div>
                                      )
                                    )}
                                </Fragment>
                              )
                            )}
                        </div>
                        <div className="dropdown">
                          <div
                            className="dropdown-header"
                            onClick={toggleDropdownRestaurants}
                          >
                            <p
                              style={{
                                fontWeight: isOpenItemRestaurants ? 600 : 400,
                              }}
                            >
                              {t("nav.restaurants")}
                            </p>

                            <Icon
                              name={`${
                                isOpenItemRestaurants ? "up2" : "down2"
                              }`}
                              list="arrows"
                              height={12}
                            />
                          </div>
                          {isOpenItemRestaurants &&
                            dataMenu?.restaurantes.map(
                              (data: any, index: number) => (
                                <Fragment key={index}>
                                  <div
                                    className="dropdown-content"
                                    onClick={() => toggleDropdownItems(index)}
                                  >
                                    <div className="dropdown-text">
                                      <p
                                        style={{
                                          fontWeight: openDropdowns[index]
                                            ? 600
                                            : 400,
                                        }}
                                      >
                                        {data.titulo}
                                      </p>
                                    </div>

                                    <Icon
                                      name={`${
                                        openDropdowns[index] ? "up2" : "down2"
                                      }`}
                                      list="arrows"
                                      height={12}
                                    />
                                  </div>
                                  {openDropdowns[index] &&
                                    data.resaturantes.map(
                                      (item: any, index: number) => (
                                        <div
                                          key={index}
                                          className="dropdown-hotel"
                                          style={{
                                            paddingLeft: "16px",
                                          }}
                                          onClick={() => {
                                            navigate(item.redireccion);
                                            setIsOpen(false);
                                            setOpenDropdowns({
                                              [index]: false,
                                            });
                                            setIsOpenItemRestaurants(false);
                                            window.scrollTo(0, 0);
                                          }}
                                        >
                                          <p>{item.titulo}</p>
                                        </div>
                                      )
                                    )}
                                </Fragment>
                              )
                            )}
                        </div>
                        <div className="dropdown">
                          <div
                            className="dropdown-header"
                            onClick={() => {
                              navigate(`/${i18n.language}/eventos`);
                              setIsOpen(false);
                            }}
                          >
                            <p>{t("nav.enterprises")}</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <div
                            className="dropdown-header"
                            onClick={() => {
                              navigate(`/${i18n.language}/eventos`);
                              setIsOpen(false);
                            }}
                          >
                            <p>{t("nav.events")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            // TODO searchbox
            <div className="navmobile__container--open">
              <div>
                <Icon
                  name={`${isScrolled ? "sandwich-white" : "sandwich-black"}`}
                  list="items"
                  width={30}
                  height={30}
                  onClick={handleToggle}
                />
              </div>
              <Link className="imgcenter" to={`/${i18n.language}/home`}>
                <img
                  src={`${isScrolled ? CAwhite : CAblack}`}
                  alt="Logo"
                  width={133}
                />
              </Link>
              <div className="ismobile">
                <Button type="gold" onClick={toogleSearch}>
                  {t("general.book")}
                </Button>
              </div>
            </div>
          )}
        </div>
        {isSearch && (
          <div className="boxSearch" ref={searchRef}>
            <div className="boxSearch__container">
              <Searchboxgeneral
                nameHotel={hotelData?.titulo}
                id_hotel={hotelData?.id_travel_click}
                close={toogleSearch}
                codeagencies={agency?.codeagency || ""}
                themeCode={agency?.tema}
                currencyAgency={agency?.moneda}
                adultsAgency={agency?.cantidad_personas}
                hiddenPrices={agency?.codeagency ? true : false}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const [isSearch, setIsSearch] = useState(false);

  const toogleSearch = () => {
    setIsSearch(!isSearch);
  };

  useEffect(() => {
    if (isSearch) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isSearch]);

  const searchRef = useRef<HTMLDivElement>(null);

  const handleClickOutsideRef = (event: any) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setIsSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideRef);
    return () =>
      document.removeEventListener("mousedown", handleClickOutsideRef);
  }, []);

  const [dataMenu, setDataMenu] = useState<any>([]);

  //NUEVO

  const [showDropdown, setShowDropdown] = useState(false);
  const [showPromotions, setshowPromotions] = useState(false);
  const [showRestaurants, setshowRestaurants] = useState(false);

  const [isOpenItem, setIsOpenItem] = useState(false);
  const [isOpenItemOfertas, setIsOpenItemOfertas] = useState(false);
  const [isOpenItemRestaurants, setIsOpenItemRestaurants] = useState(false);

  const [openDropdowns, setOpenDropdowns] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleDropdownItem = () => {
    setIsOpenItem(!isOpenItem);
    setIsOpenItemOfertas(false);
    setIsOpenItemRestaurants(false);
  };

  const toggleDropdownOfertas = () => {
    setIsOpenItemOfertas(!isOpenItemOfertas);
    setIsOpenItem(false);
    setIsOpenItemRestaurants(false);
  };

  const toggleDropdownRestaurants = () => {
    setIsOpenItemRestaurants(!isOpenItemRestaurants);
    setIsOpenItem(false);
    setIsOpenItemOfertas(false);
  };

  const toggleDropdownItems = (index: number) => {
    setOpenDropdowns((prevState) => {
      const newOpenDropdowns = { ...prevState };
      Object.keys(newOpenDropdowns).forEach((key) => {
        if (Number(key) !== index) {
          newOpenDropdowns[Number(key)] = false;
        }
      });
      return {
        ...newOpenDropdowns,
        [index]: !prevState[index],
      };
    });
  };

  const handleMouseEnterDestinations = () => {
    setShowDropdown(true);
  };

  const handleMouseLeaveDestinations = () => {
    setShowDropdown(false);
    setActiveTab("destinos");
    handleCloseClick();
  };

  const handleMouseEnterPromotions = () => {
    setshowPromotions(true);
  };

  const handleMouseLeavePromotions = () => {
    setshowPromotions(false);
  };

  const handleMouseEnterRestaurants = () => {
    setshowRestaurants(true);
  };

  const handleMouseLeaveRestaurants = () => {
    setshowRestaurants(false);
  };

  const combinedScrolledState = isScrolled;

  useEffect(() => {
    if (language && coin) {
      getCurrencies({ language, navigate });
      getBoxSearch({ coin, navigate });
      getMenuOferta({ language, setDataMenu, navigate });
    }
  }, [language, coin]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      if (scrollTop > 385) {
        setIsScrolledButton(true);
      } else {
        setIsScrolledButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
  }, [isOpen]);

  return (
    <header className="navbar">
      <div className="ismobilen">{navMobSandwich()}</div>

      <div className="isdesktopn">
        <div
          className={`navweb ${
            isRoute
            ? `white ${isScrolled ? "scrolled" : ""}`
            : combinedScrolledState
            ? "scrolled"
            : ""
          }`}
        >
          <div className="navweb__container">
            <nav
              className="navweb__container--nav"
              style={{
                justifyContent: isChangeHotel ? "space-between" : "flex-start",
              }}
            >
              {isScrolled ? (
                isChangeHotel ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {/* <Icon
                    name={`${isScrolled ? "sandwich-white" : "sandwich-black"}`}
                    list="items"
                    width={30}
                    height={30}
                    onClick={handleToggle}
                  /> */}
                    <Link className="cursor" to={`/${i18n.language}/home`}>
                      <img src={`${isRoute ? CAblack : CAwhite}`} alt="Logo" />
                    </Link>
                  </div>
                ) : (
                  <Link className="cursor" to={`/${i18n.language}/home`}>
                    <img src={`${isRoute ? CAblack : CAwhite}`} alt="Logo" />
                  </Link>
                )
              ) : (
                <Link className="cursor" to={`/${i18n.language}/home`}>
                  <img src={`${isRoute ? CAblack : CAwhite}`} alt="Logo" />
                </Link>
              )}

              {isScrolled ? (
                isChangeHotel ? (
                  navHotel()
                ) : (
                  <div style={{ width: "100%" }}>
                    <div className="buttons">
                      <div className="icon__select">
                        {/* <Icon
                          name="exchange"
                          list="items"
                          height={16}
                          width={16}
                          className="cursor"
                        /> */}
                        <select value={coin} onChange={handleSelectCurrency}>
                          {currencies.map((currency: any) => (
                            <option value={currency.moneda} key={currency.id}>
                              {currency.moneda}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="icon__select">
                        <Icon
                          name="world"
                          list="items"
                          height={16}
                          width={16}
                          className="cursor"
                        />
                        <select
                          value={i18n.language}
                          onChange={handleChangeLanguage}
                        >
                          {Object.keys(i18n.options?.resources || {}).map(
                            (lng) => (
                              <option key={lng} value={lng}>
                                {t(`languages.${lng}`)}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className="icontext">
                        <div onClick={() => handlePhoneCall("+5113916500")}>
                          <Icon
                            name="phone"
                            list="items"
                            width={16}
                            height={16}
                          />
                        </div>
                        <div onClick={() => handlePhoneCall("+5113916500")}>
                          <Text
                            type="paragraph"
                            text="+511-3916500"
                            className="phone"
                          />
                        </div>
                      </div>
                      <div
                        onClick={() =>
                          navigate(
                            `/${i18n.language}/${t(
                              "routes.reservations-agents"
                            )}`
                          )
                        }
                      >
                        <Text
                          type="paragraph"
                          text={t("nav.agencies")}
                          className={`phone ${location.pathname.startsWith(`/${i18n.language}/${t("routes.reservations-agents")}`) ? "activeReservation" : ""}`}
                        />
                      </div>
                      <div className="icontext">
                        <div>
                          <Icon
                            name="user2"
                            list="items"
                            width={16}
                            height={16}
                          />
                        </div>
                        <div className="title">Socio Life</div>
                        <div className="phone2">
                          <p
                            onClick={() =>
                              redirectUrlExternal(
                                "https://life.casa-andina.com/login"
                              )
                            }
                          >
                            {t(`nav.login`)}
                          </p>
                          <hr />
                          <p
                            onClick={() =>
                              redirectUrlExternal(
                                "https://life.casa-andina.com/register"
                              )
                            }
                          >
                            {t(`nav.signup`)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="list">
                      <ul className="navbar-list">
                        {dataMenu &&
                          dataMenu.urls_navbar &&
                          dataMenu?.urls_navbar[0]?.titulo && (
                            <li
                              className="navbar-item normal navbar-item-destacado"
                              onClick={() => {
                                navigate(dataMenu?.urls_navbar[0]?.redireccion);
                              }}
                            >
                              {dataMenu?.urls_navbar[0]?.titulo}
                            </li>
                          )}
                        <li
                          className={`navbar-item normal ${
                            location.pathname.startsWith(`/${i18n.language}/${t("routes.destinations")}`) ? "active" : ""
                          } ${showDropdown ? "active" : ""}`}
                          onClick={() => {
                            navigate(`/${i18n.language}/${t("routes.destinations")}`);
                            setShowDropdown(false);
                          }}
                          onMouseEnter={handleMouseEnterDestinations}
                          onMouseLeave={handleMouseLeaveDestinations}
                        >
                          {t("nav.destinations")}
                        </li>
                        <li
                          className={`navbar-item normal ${
                            location.pathname.startsWith(`/${i18n.language}/${t("routes.offers")}`) ? "active" : ""
                          } ${showPromotions ? "active" : ""}`}
                          onClick={() => {
                            navigate(`/${i18n.language}/${t("routes.offers")}`);
                            setshowPromotions(false);
                          }}
                          onMouseEnter={handleMouseEnterPromotions}
                          onMouseLeave={handleMouseLeavePromotions}
                        >
                          {t("nav.offers")}
                        </li>
                        <li
                          className={`navbar-item normal ${
                            location.pathname.startsWith(`/${i18n.language}/${t("routes.restaurants")}`) ? "active" : ""
                          } ${showRestaurants ? "active" : ""}`}
                          onClick={() => {
                            navigate(`/${i18n.language}/${t("routes.restaurants")}`);
                            setshowRestaurants(false);
                          }}
                          onMouseEnter={handleMouseEnterRestaurants}
                          onMouseLeave={handleMouseLeaveRestaurants}
                        >
                          {t("nav.restaurants")}
                        </li>
                        <li
                          className="navbar-item normal"
                          onClick={() => {
                            window.open(
                              "https://life.casa-andina.com/",
                              "_blank"
                            );
                          }}
                        >
                          {t("nav.life")}
                        </li>
                        <li
                          className={`navbar-item normal ${
                            location.pathname.startsWith(`/${i18n.language}/${t("routes.corporate-rates")}`) ? "active" : ""
                          }`}
                          onClick={() => {
                            navigate(`/${i18n.language}/${t("routes.corporate-rates")}`);
                          }}
                        >
                          {t("nav.enterprises")}
                        </li>
                        <li
                          className={`navbar-item normal ${
                            location.pathname.startsWith(`/${i18n.language}/${t("routes.events")}`) ? "active" : ""
                          }`}
                          onClick={() => {
                            navigate(`/${i18n.language}/${t("routes.events")}`);
                          }}
                        >
                          {t("nav.events")}
                        </li>
                      </ul>
                    </div>
                  </div>
                )
              ) : (
                <div style={{ width: "100%" }}>
                  <div className="buttons">
                    <div className="icon__select">
                      {/* <Icon
                        name="exchange"
                        list="items"
                        height={16}
                        width={16}
                        className="cursor"
                      /> */}
                      <select value={coin} onChange={handleSelectCurrency}>
                        {currencies.map((currency: any) => (
                          <option value={currency.moneda} key={currency.id}>
                            {currency.moneda}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="icon__select">
                      <Icon
                        name="world"
                        list="items"
                        height={16}
                        width={16}
                        className="cursor"
                      />
                      <select
                        value={i18n.language}
                        onChange={handleChangeLanguage}
                      >
                        {Object.keys(i18n.options?.resources || {}).map(
                          (lng) => (
                            <option key={lng} value={lng}>
                              {t(`languages.${lng}`)}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="icontext">
                      <div onClick={() => handlePhoneCall("+5113916500")}>
                        <Icon
                          name="phone"
                          list="items"
                          width={16}
                          height={16}
                        />
                      </div>
                      <div onClick={() => handlePhoneCall("+5113916500")}>
                        <Text
                          type="paragraph"
                          text="+511-3916500"
                          className="phone"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        navigate(
                          `/${i18n.language}/${t("routes.reservations-agents")}`
                        )
                      }
                    >
                      <Text
                        type="paragraph"
                        text={t("nav.agencies")}
                        className={`phone ${location.pathname.startsWith(`/${i18n.language}/${t("routes.reservations-agents")}`) ? "activeReservation" : ""}`}
                      />
                    </div>
                    <div className="icontext">
                      <div>
                        <Icon
                          name="user2"
                          list="items"
                          width={16}
                          height={16}
                        />
                      </div>
                      <div className="title">Socio Life</div>
                      <div className="phone2">
                        <p
                          onClick={() =>
                            redirectUrlExternal(
                              "https://life.casa-andina.com/login"
                            )
                          }
                        >
                          {t(`nav.login`)}
                        </p>
                        <hr />
                        <p
                          onClick={() =>
                            redirectUrlExternal(
                              "https://life.casa-andina.com/register"
                            )
                          }
                        >
                          {t(`nav.signup`)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="list">
                    <ul className="navbar-list">
                      {dataMenu &&
                        dataMenu.urls_navbar &&
                        dataMenu?.urls_navbar[0]?.titulo && (
                          <li
                            className="navbar-item normal navbar-item-destacado"
                            onClick={() => {
                              navigate(dataMenu.urls_navbar[0].redireccion);
                            }}
                          >
                            {dataMenu?.urls_navbar[0]?.titulo}
                          </li>
                        )}
                      <li
                        className={`navbar-item normal ${
                          location.pathname.startsWith(`/${i18n.language}/${t("routes.destinations")}`) ? "active" : ""
                        } ${showDropdown ? "active" : ""}`}
                        onClick={() => {
                          navigate(`/${i18n.language}/${t("routes.destinations")}`);
                          setShowDropdown(false);
                        }}
                        onMouseEnter={handleMouseEnterDestinations}
                        onMouseLeave={handleMouseLeaveDestinations}
                      >
                        {t("nav.destinations")}
                      </li>
                      <li
                        className={`navbar-item normal ${
                          location.pathname.startsWith(`/${i18n.language}/${t("routes.offers")}`) ? "active" : ""
                        } ${showPromotions ? "active" : ""}`}
                        onClick={() => {
                          navigate(`/${i18n.language}/${t("routes.offers")}`);
                          setshowPromotions(false);
                        }}
                        onMouseEnter={handleMouseEnterPromotions}
                        onMouseLeave={handleMouseLeavePromotions}
                      >
                        {t("nav.offers")}
                      </li>
                      <li
                        className={`navbar-item normal ${
                          location.pathname.startsWith(`/${i18n.language}/${t("routes.restaurants")}`) ? "active" : ""
                        } ${showRestaurants ? "active" : ""}`}
                        onClick={() => {
                          navigate(`/${i18n.language}/${t("routes.restaurants")}`);
                          setshowRestaurants(false);
                        }}
                        onMouseEnter={handleMouseEnterRestaurants}
                        onMouseLeave={handleMouseLeaveRestaurants}
                      >
                        {t("nav.restaurants")}
                      </li>
                      <li
                        className="navbar-item normal"
                        onClick={() => {
                          window.open(
                            "https://life.casa-andina.com/",
                            "_blank"
                          );
                        }}
                      >
                        {t("nav.life")}
                      </li>
                      <li
                        className={`navbar-item normal ${
                          location.pathname.startsWith(`/${i18n.language}/${t("routes.corporate-rates")}`) ? "active" : ""
                        }`}
                        onClick={() => {
                          navigate(`/${i18n.language}/${t("routes.corporate-rates")}`);
                        }}
                      >
                        {t("nav.enterprises")}
                      </li>
                      <li
                        className={`navbar-item normal ${
                          location.pathname.startsWith(`/${i18n.language}/eventos`) ? "active" : ""
                        }`}
                        onClick={() => {
                          navigate(`/${i18n.language}/${t("routes.events")}`);
                        }}
                      >
                        {t("nav.events")}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </nav>
            {showDropdown && (
              <div
                className="dropdown-content"
                onMouseEnter={handleMouseEnterDestinations}
                onMouseLeave={handleMouseLeaveDestinations}
              >
                <div className="dropdown-content__container">
                  <div className={`tabs ${showDropdown ? "visible" : ""}`}>
                    <div
                      className={`tab ${
                        activeTab === "destinos" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("destinos")}
                    >
                      {t("nav.ourDestinations")}
                    </div>
                    <div
                      className={`tab ${
                        activeTab === "hoteles" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("hoteles")}
                    >
                      {t("nav.ourHotels")}
                    </div>
                    <div
                      className="navbar-item normal navbar-item-destacado our-hotels-button"
                      onClick={navigateHotelsView}
                    >
                      {activeTab === "destinos" ? t("nav.seeOurDestinations") : t("nav.seeOurHotels")}
                    </div>
                  </div>
                  <div className="tab-content">
                    {activeTab === "destinos" ? (
                      <div className="destinos-container">
                        <div
                          className={`destinos-list ${
                            selectedDestination !== null
                              ? "single-column"
                              : "grid-column"
                          }`}
                          style={{ gridTemplateRows: `repeat(${Math.ceil((dataMenu?.destinos?.length || 0) / 5)}, 1fr)` }}
                        >
                          {dataMenu?.destinos?.map(
                            (data: any, index: number) => (
                              <div
                                key={index}
                                className={`destination ${
                                  selectedDestination === index
                                    ? "active"
                                    : "inactive"
                                }`}
                                onClick={() => handleDestinationClick(index)}
                              >
                                <div className="destination-name">
                                  <div>{data.titulo}</div>
                                  <div>({data.num_hoteles})</div>
                                </div>
                                {selectedDestination === index && (
                                  <Icon
                                    name="arrowrr"
                                    list="arrows"
                                    width={24}
                                    height={24}
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                        {selectedDestination !== null && (
                          <div className="hoteles-content1">
                            <div className="hoteles-content1__close">
                              <p className="text">{t(`nav.goToDestination`)}</p>

                              <Icon
                                name="close"
                                list="items"
                                width={20}
                                height={20}
                                className="closex"
                                onClick={handleCloseClick}
                              />
                            </div>
                            <div
                              className="hotelname"
                              onClick={() => {
                                navigate(
                                  dataMenu?.destinos[selectedDestination]
                                    .redireccion
                                );
                                setShowDropdown(false);
                                setSelectedDestination(null);
                              }}
                            >
                              {dataMenu?.destinos[selectedDestination].titulo}
                            </div>
                            <p className="selecthotel">{t(`nav.selectAHotel`)}</p>
                            <ul className="hotels-list">
                              {dataMenu?.destinos[
                                selectedDestination
                              ].hoteles.map((item: any, index: number) => (
                                <li
                                  key={index}
                                  className="hotel-item"
                                  onClick={() => {
                                    navigate(item.redireccion);
                                    setShowDropdown(false);
                                    setSelectedDestination(null);
                                  }}
                                >
                                  {item.titulo}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="hoteles-content2">
                        {dataMenu?.hoteles?.map((data: any, index: number) => (
                          <div key={index} className="category2">
                            <div
                              className="category-title2"
                              onClick={() => {
                                navigate(data.redireccion);
                                setShowDropdown(false);
                              }}
                              style={{
                                borderBottom: `4px solid ${data.color}`,
                              }}
                            >
                              {data.titulo}
                            </div>
                            <div className="hotels-list2">
                              <ul>
                                {data.hoteles
                                  ?.filter(
                                    (item: any, idx: number) => idx % 2 === 0
                                  )
                                  .map((item: any, index: number) => (
                                    <li
                                      key={index}
                                      className="hotel-item"
                                      onClick={() => {
                                        navigate(item.redireccion);
                                        setShowDropdown(false);
                                        setActiveTab("destinos");
                                      }}
                                    >
                                      {item.titulo}
                                    </li>
                                  ))}
                              </ul>
                              <ul>
                                {data.hoteles
                                  ?.filter(
                                    (item: any, idx: number) => idx % 2 !== 0
                                  )
                                  .map((item: any, index: number) => (
                                    <li
                                      key={index}
                                      className="hotel-item"
                                      onClick={() => {
                                        navigate(item.redireccion);
                                        setShowDropdown(false);
                                        setActiveTab("destinos");
                                      }}
                                    >
                                      {item.titulo}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {showPromotions && (
              <div
                className="dropdown-content"
                onMouseEnter={handleMouseEnterPromotions}
                onMouseLeave={handleMouseLeavePromotions}
              >
                <div className="dropdown-content__container">
                  <div className="tab-content">
                    <div className="hoteles-content2">
                      {dataMenu &&
                        dataMenu?.ofertas?.map((data: any, index: number) => (
                          <div key={index} className="category">
                            <p
                              className="category-title"
                              style={{
                                borderBottom: `1px solid ${
                                  isRoute ? "black" : "white"
                                } `,
                              }}
                            >
                              {data.titulo}
                            </p>
                            <div className="hotels-list">
                              <ul>
                                {data.promociones
                                  .filter(
                                    (item: any, idx: number) => idx % 2 === 0
                                  )
                                  .map((item: any, idx: number) => (
                                    <li
                                      key={idx}
                                      className="hotel-item nlink"
                                      onClick={() => {
                                        navigate(item.redireccion);
                                        setshowPromotions(false);
                                      }}
                                      style={{
                                        color: isRoute ? "black" : "white",
                                      }}
                                    >
                                      {item.titulo}
                                    </li>
                                  ))}
                              </ul>
                              <ul>
                                {data.promociones
                                  .filter(
                                    (item: any, idx: number) => idx % 2 !== 0
                                  )
                                  .map((item: any, idx: number) => (
                                    <li
                                      key={idx}
                                      className="hotel-item nlink"
                                      onClick={() => {
                                        navigate(item.redireccion);
                                        setshowPromotions(false);
                                      }}
                                      style={{
                                        color: isRoute ? "black" : "white",
                                      }}
                                    >
                                      <p>{item.titulo}</p>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showRestaurants && (
              <div
                className="dropdown-content"
                onMouseEnter={handleMouseEnterRestaurants}
                onMouseLeave={handleMouseLeaveRestaurants}
              >
                <div className="dropdown-content__container">
                  <div className="tab-content">
                    <div className="hoteles-content2">
                      {dataMenu &&
                        dataMenu?.restaurantes?.map(
                          (data: any, index: number) => (
                            <div key={index} className="category">
                              <div
                                className="category-title"
                                onClick={() => {
                                  navigate(data.redireccion);
                                  setshowRestaurants(false);
                                }}
                                style={{
                                  borderBottom: `4px solid ${data.color}`,
                                }}
                              >
                                {data.titulo}
                              </div>
                              <div className="hotels-list">
                                <ul>
                                  {data.resaturantes
                                    .filter(
                                      (item: any, idx: number) => idx % 2 === 0
                                    )
                                    .map((hotel: any, idx: number) => (
                                      <li
                                        key={idx}
                                        className="hotel-item nlink"
                                        onClick={() => {
                                          navigate(hotel.redireccion);
                                          setshowRestaurants(false);
                                        }}
                                        style={{
                                          color: isRoute ? "black" : "white",
                                        }}
                                      >
                                        {hotel.titulo}
                                      </li>
                                    ))}
                                </ul>
                                <ul>
                                  {data.resaturantes
                                    .filter(
                                      (item: any, idx: number) => idx % 2 !== 0
                                    )
                                    .map((hotel: any, idx: number) => (
                                      <li
                                        key={idx}
                                        className="hotel-item nlink"
                                        onClick={() => {
                                          navigate(hotel.redireccion);
                                          setshowRestaurants(false);
                                        }}
                                        style={{
                                          color: isRoute ? "black" : "white",
                                        }}
                                      >
                                        {hotel.titulo}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
