
import Axios from "actions/api";
import { endpoints } from "actions/endpoints/services";
import { setDataRestaurants, setDataRestaurantsCategory, setDataRestaurantsPlaces, setDataRestaurantsRestaurant, setLoading } from "redux/actions/language";
import { IGetRestaurants, IGetRestaurantsCategory, IGetRestaurantsPlace, IGetRestaurantsRestaurant } from "./props";
import { updateMetadata } from "utils/function";
import * as Sentry from '@sentry/react';
import { setLoadingPromotionSubPages } from "redux/slices/language";

export const getRestaurants = async ({
  language,
  coin,
  navigate,
}: IGetRestaurants) => {
  try {
    const response = await Axios.request({
      method: "get",
      url: endpoints.restaurantes,
      headers: {
        "Accept-Language": language,
        "Currency-Code": coin,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setDataRestaurants(data)
    updateMetadata({
      title: data?.meta_data?.titulo || 'Casa Andina Restaurantes',
      description: data?.meta_data?.descripcion,
      canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
      hreflang: [
        { hreflang: 'es', href: data?.meta_data?.url_canonica_es },
        { hreflang: 'en', href: data?.meta_data?.url_canonica_en },
        { hreflang: 'pt', href: data?.meta_data?.url_canonica_pt },
      ],
    })

    return data;

  } catch (error) {
    // alert('No se pudo cargar la vista restaurantes')
    // console.log("Server error: ", error);
    // Sentry.captureException(error);
    // navigate('/es/error');
  }
}

export const getRestaurantsPlace = async ({
  places,
  language,
  coin,
  navigate
}: IGetRestaurantsPlace) => {
  try {
    setLoading(true);

    const restauranteslugar = `/restaurantes/tipo_restaurante/${places}`;

    const response = await Axios.request({
      method: "get",
      url: restauranteslugar,
      headers: {
        "Accept-Language": language,
        "Currency-Code": coin,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setDataRestaurantsPlaces(data)
    updateMetadata({
      title: data?.meta_data?.titulo || 'Casa Andina Restaurantes',
      description: data?.meta_data?.descripcion,
      canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
      hreflang: [
        { hreflang: 'es', href: data?.meta_data?.url_canonica_es },
        { hreflang: 'en', href: data?.meta_data?.url_canonica_en },
        { hreflang: 'pt', href: data?.meta_data?.url_canonica_pt },
      ],
    })

    return data;

  } catch (error) {
    // navigate('*', { replace: false });
  } finally {
    setLoading(false);
  }
}

export const getRestaurantsCategory= async ({
  category,
  language,
  coin,
  navigate
}: IGetRestaurantsCategory) => {
  try {
    setLoading(true);
    setLoadingPromotionSubPages(true);
    const restauranteslugar = `/restaurantes/categoria/${category}`;

    const response = await Axios.request({
      method: "get",
      url: restauranteslugar,
      headers: {
        "Accept-Language": language,
        "Currency-Code": coin,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setDataRestaurantsCategory(data)
    updateMetadata({
      title: data?.meta_data?.titulo || 'Casa Andina Restaurantes',
      description: data?.meta_data?.descripcion,
      canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
      hreflang: [
        { hreflang: 'es', href: data?.meta_data?.url_canonica_es },
        { hreflang: 'en', href: data?.meta_data?.url_canonica_en },
        { hreflang: 'pt', href: data?.meta_data?.url_canonica_pt },
      ],
    })

    return data;

  } catch (error) {
    // navigate('*', { replace: false });
  } finally {
    setLoading(false);
    setLoadingPromotionSubPages(false);
  }
}

export const getRestaurantsRestaurant = async ({
  restaurant,
  language,
  navigate
}: IGetRestaurantsRestaurant) => {
  try {
    setLoading(true);
    const restaurantes = `/restaurantes/${restaurant}`;

    const response = await Axios.request({
      method: "get",
      url: restaurantes,
      headers: {
        "Accept-Language": language,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setDataRestaurantsRestaurant(data)
    updateMetadata({
      title: data?.meta_data?.titulo || 'Casa Andina Restaurantes',
      description: data?.meta_data?.descripcion,
      canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
      hreflang: [
        { hreflang: 'es', href: data?.meta_data?.url_canonica_es },
        { hreflang: 'en', href: data?.meta_data?.url_canonica_en },
        { hreflang: 'pt', href: data?.meta_data?.url_canonica_pt },
      ],
    })

    return data;

  } catch (error) {
    // navigate('*', { replace: false });
  } finally {
    setLoading(false);
  }
}