import { useEffect } from "react";
import { BannerSlider } from "components/organisms";
import { useAppSelector } from "hooks/useAppSelector";
import { getPromotions } from "actions/promotions/services";
import SectionCardsPromotions from "components/organisms/section-cards-promotions";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";

const Promotions = () => {
  const navigate = useNavigate();
  const { language, coin, loading, data, banners, banner_tira, sections } =
    useAppSelector((state) => ({
      language: state.config.language,
      loading: state.language.loadingPromotions,
      coin: state.config.coin,
      data: state.language.dataPromotion,
      banners: state.language.dataPromotion?.banners || [],
      banner_tira: state.language.dataPromotion?.banner_tira || [],
      sections: state.language.dataPromotion?.secciones || [],
    }));

  useEffect(() => {
    if (language && coin) {
      getPromotions({ language, coin, navigate });
      window.scrollTo(0, 0);
    }
  }, [language, coin]);

  if (loading || !data) {
    return (
      <>
        <BannerSlider data={banners} />
        {[1, 2, 3].map((item, index) => (
          <div className="promotionsbanks">
            <div className="promotionsbanks__container">
              <div className="skeleton" style={{ marginBottom: "20px" }}>
                <div className="skeleton__container"></div>
              </div>

              <div className="promotionsbanks__description">
                <div className="promotionsbanks__description--swiper">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    {[1, 2, 3].map((item, index) => (
                      <div
                        className="skeleton"
                        style={{ height: "245px", width: "264px" }}
                      >
                        <div
                          className="skeleton__container"
                          style={{ width: "100%" }}
                        ></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
  return (
    <>
      {banners.length > 0 && <BannerSlider data={banners} />}

      {sections.length > 0 && (
        <SectionCardsPromotions data={sections} banner={banner_tira} />
      )}
    </>
  );
};

export default Promotions;
