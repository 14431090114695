import { useEffect, useState } from "react";
import {
  PromotionsBanks,
  PromotionsCampaign,
  PromotionsLoader,
  PromotionsPackages,
  PromotionsRelax,
} from "components/templates";
import { getPromotionsSubPages } from "actions/promotions/services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "hooks/useAppSelector";
import { BannerSlider } from "components/organisms";
import { PromotionNotFound } from "pages";

const PromotionsSubPages = () => {
  const { subpages } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const language = useAppSelector((state) => state.config.language);
  const coin = useAppSelector((state) => state.config.coin);
  const data = useAppSelector((state) => state.language.dataPromotionSubPages);
  const loading = useAppSelector(
    (state) => state.language.loadingPromotionSubPages
  );

  const [localParams, setLocalParams] = useState({
    sort_by: false,
    destinos: "",
  });

  const handleIdFilter = (id: any, destination: any) => {
    id = id ?? "";
    destination = destination ?? "";

    setLocalParams({
      sort_by: id,
      destinos: destination,
    });

    // const sort_byGeneral = id ? `sort_by=${id}` : ''
    // const destinationQuery = destination ? `destinos=${destination}` : ''

    // const urlParams = `?${sort_byGeneral}&${destinationQuery}`
    // navigate(urlParams, { replace: true })
  };

  const subPagesType = () => {
    switch (data?.categoria?.titulo?.toLowerCase()) {
      case "banco":
        return <PromotionsBanks data={data} idFilter={handleIdFilter} />;
      case "paquete":
        return (
          <PromotionsRelax
            data={data}
            idFilter={handleIdFilter}
            loading={loading}
          />
        );
      case "campaña":
        return (
          <PromotionsCampaign
            data={data}
            idFilter={handleIdFilter}
            loading={loading}
          />
        );
      case "paquete exclusivo":
        return <PromotionsPackages data={data} loading={loading} />;
    }
  };

  // Reload all the page
  useEffect(() => {
    if (subpages && language && coin) {
      getPromotionsSubPages({
        language,
        subpages,
        coin,
        params: localParams,
        navigate,
        isloaderActive: true,
      });
    }
  }, [subpages, language, coin, location.pathname]);

  // Reload and update only promotion cards
  useEffect(() => {
    if (subpages && language && coin) {
      getPromotionsSubPages({
        language,
        subpages,
        coin,
        params: localParams,
        navigate,
        isloaderActive: false,
      });
    }
  }, [localParams.sort_by, localParams.destinos]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  if (!loading && data?.promocion_expirada) {
    return <PromotionNotFound data={data} />
  }

  return (
    <>
      <BannerSlider data={data?.banners ?? []} loading={loading} />
      {loading ? <PromotionsLoader /> : subPagesType()}
    </>
  );
};

export default PromotionsSubPages;
