import React, { useEffect } from "react";
import Section from "components/molecules/section";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "components/atoms";
import useRecaptcha from "hooks/useRecaptcha";
import { useForm, Controller } from "react-hook-form";
import { toast } from "sonner";
import { REACT_APP_ENDPOINT_CA } from "typings/env";
import { logSentryErrorResponse } from "utils/function";

interface IPromotionNotFound {
  data?: {
    promocion_expirada: boolean;
    id: number;
    nombre: string;
    es_promocion_cyber: boolean;
    titulo_destacado: string;
    img: {
      path: string;
      alt: string;
    } | null;
    logo: {
      path: string;
      alt: string;
    } | null;
  };
}

interface IDataProps {
  correo: string;
  tipo: string;
  politicas_datos_personales: boolean;
  publicidad_y_promociones: boolean;
}

const defaultValues: IDataProps = {
  correo: "",
  tipo: "",
  politicas_datos_personales: false,
  publicidad_y_promociones: false,
};

const PromotionNotFound = ({ data }: IPromotionNotFound) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { getRecaptchaToken } = useRecaptcha();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<IDataProps>({
    mode: "onChange",
    defaultValues,
  });


  useEffect(() => {
    // Si no es promoción cyber redirigir a ofertas
    if (data && !data.es_promocion_cyber) {
      navigate(`/${i18n.language}/${t("routes.offers")}`)
    }
    else {
      navigate(`${window.location.pathname}?nav=true`);
    }
  }, [data]);

  const onSubmit = async (data: object) => {
    const token = await getRecaptchaToken();

    if (!token) {
      toast.error("Error al obtener el token de reCAPTCHA", {
        position: "top-right",
      });
      return;
    }

    try {
      const { correo, tipo } = data as IDataProps;

      // console.log(correo, tipo);

      const response = await fetch(
        `${REACT_APP_ENDPOINT_CA}/form/suscripcion`,
        {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "g-recaptcha-response": token,
          },
          body: JSON.stringify({
            correo: correo,
            tipo: tipo,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        toast.success(data.message, { position: "top-right" });
        reset();
      } else {
        logSentryErrorResponse(response, 'Form - Suscripcion Form');
        console.error(response);
        toast.error(t("forms.errorSendingData"), { position: "top-right" });
      }
    } catch (error) {
      // Sentry.captureException(error); // Captura el error en Sentry
      // console.error(error);
      toast.error(t("forms.errorSendingData"), { position: "top-right" });
    }
  };

  return (
    <>
      <Section>
        <div className="promotion-not-found">
          <div className="promotion-not-found__container">
            <div className="image-container">
              <img src={data?.logo?.path} alt={data?.logo?.alt} />
              <h1 className="title"><b>Ya terminó,</b></h1>
            </div>
            <h1 className="title" style={{ marginBottom: "25px" }}>pero pronto regresará.</h1>

            <p className="promotion-pararaph">¡Gracias por tu interés! <b>Déjanos tus datos y sé el primero en enterarte de nuestras próximas promociones.</b></p>

            <p className="promotion-pararaph">Ingresa tus datos:</p>

            <div className="footersection__container--right">
              <form onSubmit={handleSubmit(onSubmit)} id="promotion_suscripcion_form">
                <div className="label-input">
                  <label htmlFor="tipoPersona">
                    <input
                      type="radio"
                      id="tipoPersona"
                      value="Persona"
                      defaultChecked
                      {...register("tipo", { required: true })}
                    />
                    {t("footer.person")}
                  </label>

                  <label htmlFor="tipoEmpresa">
                    <input
                      type="radio"
                      id="tipoEmpresa"
                      value="Empresa"
                      {...register("tipo", { required: true })}
                    />
                    {t("footer.company")}
                  </label>
                  {errors.tipo && <span>Seleccione un tipo de suscripción</span>}
                </div>

                <Controller
                  name="correo"
                  control={control}
                  rules={{
                    required: "El email es obligatorio",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Debe ser un email válido",
                    },
                  }}
                  render={({ field }) => (
                    <div className="promotion-input-form">
                      <input
                        type="email"
                        id="correo"
                        placeholder={t("footer.enter-your-email")}
                        {...field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let value = e.target.value;
                          if (value.endsWith(".con")) {
                            value = value.slice(0, -4) + ".com";
                          }
                          field.onChange(value);
                        }}
                      />

                      <p className="errors">
                        {errors.correo && <>{errors.correo.message}</>}
                      </p>
                    </div>
                  )}
                />

                <Controller
                  name="politicas_datos_personales"
                  control={control}
                  rules={{ required: t("forms.requiredField") }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <div className="checkbox__form">
                      <label>
                        <input
                          {...field}
                          type="checkbox"
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                        <p>{t("forms.acceptPrivacy")} <a href={`/${i18n.language}/politica-de-proteccion-de-datos-personales`} className="terms-url" target="_blank">{t("forms.acceptPrivacyLink")}.</a>*</p>
                      </label>
                      {errors.politicas_datos_personales && (
                        <span>{errors.politicas_datos_personales.message}</span>
                      )}
                    </div>
                  )}
                />

                <Controller
                  name="publicidad_y_promociones"
                  control={control}
                  rules={{ required: t("forms.requiredField") }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <div className="checkbox__form">
                      <label>
                        <input
                          {...field}
                          type="checkbox"
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                        <p>{`${t("forms.sendAds")}`}</p>
                      </label>
                      {errors.publicidad_y_promociones && (
                        <span>{errors.publicidad_y_promociones.message}</span>
                      )}
                    </div>
                  )}
                />

                <button id="submitForm" aria-label="Suscribirse" type="submit" disabled={!isValid}>
                  {t("footer.subscribe")}
                </button>
              </form>

              <h2 style={{ marginTop: "10px" }}>Quizás te interese</h2>

              <Button type="borders" style={{ fontSize: "16px" }} onClick={() => navigate(`/${i18n.language}/${t("routes.offers")}`)}>
                Ver todas las promociones
                {/* {t("general.book")} */}
              </Button>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default PromotionNotFound;
