import React, { useEffect, useState } from "react";
import { Button, Icon, Text } from "components/atoms";
import { events } from "assets/images/events";
import { getUtils } from "actions/utils/services";
import { toast } from "sonner";
import { REACT_APP_ENDPOINT_CA } from "typings/env";
import { useAppSelector } from "hooks/useAppSelector";
import { Controller, useForm } from "react-hook-form";
import Salon from "assets/images/home/salon.webp";
import "./styles.scss";
import useRecaptcha from "hooks/useRecaptcha";
import { useTranslation } from "react-i18next";
import { logSentryErrorResponse, getNextDay } from "utils/function";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { updateMetadata } from "utils/function";
import { hoursArray } from "./data";

interface IDataProps {
  dst_id: string;
  email: string;
  fecha: string;
  nombre_empresa: string;
  nombres: string;
  numero_personas: string;
  ruc: string;
  terminos_condiciones: boolean;
  politicas_datos_personales: boolean;
  publicidad_y_promociones: boolean;
  telefono: string;
  hora_inicio: string;
  hora_fin: string;
  tipo_evento_social_id: string;
  tipo_alimentacion_id: string;
  tipo_duracion_id: string;
  tipo_evento_id: string;
}

// Declara la función fbq globalmente
declare global {
  interface Window {
    fbq: (eventType: string, eventName: string, eventData?: object) => void;
  }
}

const Events = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { getRecaptchaToken } = useRecaptcha();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
  });
  const selectedOption = watch("tipo_evento_id", "option1");
  const watchCheckbox1 = watch("terminos_condiciones");
  const watchCheckbox2 = watch("politicas_datos_personales");

  const {
    language,
    destinos,
    tipo_duracion,
    tipo_alimentacion,
    tipo_eventos_social,
  } = useAppSelector((state) => ({
    language: state.config.language,
    destinos: state.language.dataUtils?.destinos || [],
    tipo_duracion: state.language.dataUtils?.tipo_duracion || [],
    tipo_alimentacion: state.language.dataUtils?.tipo_alimentacion || [],
    tipo_eventos_social: state.language.dataUtils?.tipo_eventos_social || [],
  }));

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("tipo_evento_id", event.target.value);
  };

  const data = [
    {
      img: events.Event1,
      alt: t("events.eventosTreintaYtres"),
      text: t("events.eventosTreintaYtres"),
    },
    {
      img: events.Event2,
      alt: t("events.eventosTreintaYcuatro"),
      text: t("events.eventosTreintaYcuatro"),
    },
    {
      img: events.Event3,
      alt: t("events.eventosTreintaYcinco"),
      text: t("events.eventosTreintaYcinco"),
    },
    {
      img: events.Event4,
      alt: t("events.eventosTreintaYseis"),
      text: t("events.eventosTreintaYseis"),
    },
    {
      img: events.Event5,
      alt: t("events.eventosTreintaYsiete"),
      text: t("events.eventosTreintaYsiete"),
    },
    {
      img: events.Event6,
      alt: t("events.eventosTreintaYocho"),
      text: t("events.eventosTreintaYocho"),
    },
    {
      img: events.Event7,
      alt: t("events.eventosTreintaYnueve"),
      text: t("events.eventosTreintaYnueve"),
    },
  ];

  const equipos = [
    { name: t("events.eventosCuarentaYcinco") },
    { name: t("events.eventosCuarentaYseis") },
    { name: t("events.eventosCuarentaYsiete") },
    { name: t("events.eventosCuarentaYocho") },
    { name: t("events.eventosCuarentaYnueve") },
    { name: t("events.eventosCincuenta") },
  ];

  const alimentacion = [
    { name: t("events.eventosCinco") },
    { name: t("events.eventosSeis") },
    { name: t("events.eventosSiete") },
    { name: t("events.eventosOcho") },
    { name: t("events.eventosNueve") },
    { name: t("events.eventosDiez") },
    { name: t("events.eventosOnce") },
    { name: t("events.eventosDoce") },
  ];

  const cardData = [
    { text: "Auditorio", image: events.Sala1 },
    { text: "Escuela", image: events.Sala2 },
    { text: "Mesa redondas", image: events.Sala3 },
    { text: "Herradura", image: events.Sala4 },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: object) => {
    window.fbq("track", "FormularioEventos"); // Enviar el evento al píxel de Facebook

    const token = await getRecaptchaToken();

    if (!token) {
      toast.error("Error al obtener el token de reCAPTCHA", {
        position: "top-right",
      });
      return;
    }

    try {
      setIsLoading(true);
      const {
        dst_id,
        terminos_condiciones,
        politicas_datos_personales,
        publicidad_y_promociones,
        tipo_alimentacion_id,
        tipo_duracion_id,
        tipo_evento_social_id,
        ...rest
      } = data as IDataProps;

      const response = await fetch(`${REACT_APP_ENDPOINT_CA}/form/evento`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "g-recaptcha-response": token,
        },
        body: JSON.stringify({
          ...rest,
          dst_id: parseInt(dst_id) || "",
          terminos_condiciones: terminos_condiciones ? 1 : 0,
          politicas_datos_personales: politicas_datos_personales ? 1 : 0,
          publicidad_y_promociones: publicidad_y_promociones ? 1 : 0,
          numero_personas: parseInt(rest.numero_personas) || 0,
          tipo_alimentacion_id: parseInt(tipo_alimentacion_id) || null,
          tipo_duracion_id: parseInt(tipo_duracion_id) || null,
          tipo_evento_social_id: parseInt(tipo_evento_social_id) || null,
          tipo_evento_id: selectedOption === "option1" ? 2 : 1,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        toast.success(data.message, { position: "top-right" });
        reset();
      } else {
        logSentryErrorResponse(response, "Form - Eventos Form");
        console.error(response);
        toast.error(t("forms.errorSendingData"), { position: "top-right" });
      }
    } catch (error) {
      // Sentry.captureException(error); // Captura el error en Sentry
      // console.error(error);
      toast.error(t("forms.errorSendingData"), { position: "top-right" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUtils({ language, navigate });
    window.scrollTo(0, 0);
  }, [language]);

  useEffect(() => {
    updateMetadata({
      title: "Hoteles con salones para eventos | Casa Andina",
      description:
        "Nuestros hoteles con salones para eventos son ideales para conferencias, reuniones corporativas y actividades sociales. Tenemos modernas salas, equipos y servicio de catering.",
      canonicalUrl: `https://www.casa-andina.com/${i18n.language}/eventos`,
      hreflang: [
        {
          hreflang: "es",
          href: "https://www.casa-andina.com/es/eventos",
        },
        {
          hreflang: "en",
          href: "https://www.casa-andina.com/en/eventos",
        },
        {
          hreflang: "pt",
          href: "https://www.casa-andina.com/pt/eventos",
        },
      ],
    });
  }, []);

  return (
    <>
      <div className="eventosforms">
        <div className="eventosforms__container">
          <div className="eventosforms__container--box">
            <div className="eventosforms__container--text">
              <Text
                text={t("events.eventosUno")}
                type="title"
                className="title"
              />
              <Text
                text={t("events.eventosDos")}
                type="subtitle"
                className="subtitle"
              />
              <div className="eventosforms__text--numbers">
                <div>
                  <Text text="+65" type="paragraph" className="subtitle" />
                  <Text
                    text={t("events.eventosTres")}
                    type="paragraph"
                    className="paragraph"
                  />
                </div>
                <div>
                  <Text text="10" type="paragraph" className="subtitle" />
                  <Text
                    text={t("events.eventosCuatro")}
                    type="paragraph"
                    className="paragraph"
                  />
                </div>
              </div>
              <div>
                <div className="lista">
                  {alimentacion.map((item, index) => (
                    <ul key={index}>
                      <li>{item.name}</li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
            <div className="eventosforms__container--form">
              <div>
                <Text
                  text={t("events.eventosTrece")}
                  type="subtitle"
                  className="subtitle"
                />

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="radiobutton">
                    <label htmlFor="option1">
                      <input
                        type="radio"
                        id="option1"
                        value="option1"
                        checked={selectedOption === "option1"}
                        {...register("tipo_evento_id", {
                          required: true,
                          onChange: handleOptionChange,
                        })}
                      />
                      {t("forms.corporateEvent")}
                    </label>

                    <label htmlFor="option2">
                      <input
                        type="radio"
                        id="option2"
                        value="option2"
                        checked={selectedOption === "option2"}
                        {...register("tipo_evento_id", {
                          required: true,
                          onChange: handleOptionChange,
                        })}
                      />
                      {t("forms.socialEvent")}
                    </label>
                  </div>

                  {selectedOption === "option1" && (
                    <div className="form-option1">
                      <div className="input-formcontact">
                        <Controller
                          name="nombres"
                          control={control}
                          rules={{
                            required: t("forms.enterFullName"),
                            minLength: {
                              value: 3,
                              message: t("forms.fullNameMin3"),
                            },
                          }}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="text"
                              id="nombres"
                              placeholder={`${t("forms.fullName")}*`}
                            />
                          )}
                        />
                        <span>
                          {errors.nombres && <>{errors.nombres.message}</>}
                        </span>
                      </div>
                      <div className="input-formcontact">
                        <Controller
                          name="telefono"
                          control={control}
                          rules={{
                            required: t("forms.requiredField"),
                            pattern: {
                              value: /^[0-9]+$/,
                              message: t("forms.enterOnlyNumbers"),
                            },
                          }}
                          render={({ field }) => (
                            <input
                              {...field}
                              type="tel"
                              id="telefono"
                              placeholder={`${t("forms.phone")}*`}
                            />
                          )}
                        />
                        <span>
                          {errors.telefono && <>{errors.telefono.message}</>}
                        </span>
                      </div>
                      <div className="input-formcontact">
                        <input
                          type="email"
                          id="email"
                          placeholder={`${t("forms.email")}*`}
                          {...register("email", {
                            required: true,
                            pattern: /^\S+@\S+$/i,
                          })}
                        />
                        {errors.email && (
                          <span>{t("forms.enterValidEmail")}</span>
                        )}
                      </div>
                      <div className="input-formcontact">
                        <input
                          type="number"
                          id="ruc"
                          placeholder={`${t("forms.ruc")}*`}
                          {...register("ruc", {
                            required: true,
                            pattern: /^[0-9]+$/,
                          })}
                        />
                        {errors.ruc && <span>{t("forms.requiredField")}</span>}

                        <input
                          type="text"
                          id="nombre_empresa"
                          placeholder={`${t("forms.companyName")}*`}
                          {...register("nombre_empresa", {
                            required: true,
                          })}
                        />
                        {errors.nombre_empresa && (
                          <span>{t("forms.requiredField")}</span>
                        )}
                      </div>

                      <div className="input-inlines">
                        <div>
                          <label htmlFor="">
                            <p className="form-label">{`${t(
                              "forms.eventDate"
                            )}*`}</p>
                            <input
                              type="date"
                              id="fecha"
                              placeholder={t("forms.eventDate")}
                              min={getNextDay()}
                              {...register("fecha", {
                                required: true,
                              })}
                            />
                            {errors.fecha && (
                              <span>{t("forms.requiredField")}</span>
                            )}
                          </label>
                        </div>

                        <div>
                          <select
                            id="tipo_duracion_id"
                            {...register("tipo_duracion_id", {
                              required: true,
                            })}
                            placeholder={t("forms.eventDuration")}
                          >
                            <option value="">{`${t(
                              "forms.eventDuration"
                            )}*`}</option>
                            {tipo_duracion.map(
                              (option: { id: string; nombre: string }) => (
                                <option key={option.id} value={option.id}>
                                  {option.nombre}
                                </option>
                              )
                            )}
                          </select>
                          {errors.tipo_duracion_id && (
                            <span>{t("forms.requiredField")}</span>
                          )}
                        </div>
                      </div>

                      <div className="select-form">
                        <p className="form-label">{`${t(
                          "forms.cateringRequired"
                        )}*`}</p>
                        <select
                          id="tipo_alimentacion_id"
                          {...register("tipo_alimentacion_id", {
                            required: true,
                          })}
                        >
                          <option value="">
                            {t("forms.selectYourCatering")}
                          </option>
                          {tipo_alimentacion.map(
                            (option: { id: string; nombre: string }) => (
                              <option key={option.id} value={option.id}>
                                {option.nombre}
                              </option>
                            )
                          )}
                        </select>
                        {errors.tipo_alimentacion_id && (
                          <span>{t("forms.requiredField")}</span>
                        )}
                      </div>

                      <div className="input-formcontact">
                        <label htmlFor="capacidad">
                          <p className="form-label">{`${t(
                            "forms.capacity"
                          )}*`}</p>
                          <input
                            type="number"
                            id="numero_personas"
                            min="0"
                            max="999"
                            placeholder={t("forms.capacity")}
                            {...register("numero_personas", {
                              required: true,
                            })}
                          />
                          {errors.numero_personas && (
                            <span>{t("forms.requiredField")}</span>
                          )}
                        </label>
                      </div>

                      <div className="select-form">
                        <p className="form-label">{`${t(
                          "forms.selectDestinationforInterest"
                        )}*`}</p>
                        <select
                          id="dst_id"
                          {...register("dst_id", { required: true })}
                        >
                          <option value="">
                            {t("forms.selectDestinationOption")}
                          </option>
                          {destinos.map(
                            (opcion: { id: string; nombre: string }) => (
                              <option key={opcion.id} value={opcion.id}>
                                {opcion.nombre}
                              </option>
                            )
                          )}
                        </select>
                        {errors.dst_id && (
                          <span>{t("forms.requiredField")}</span>
                        )}
                      </div>
                    </div>
                  )}

                  {selectedOption === "option2" && (
                    <div className="form-option2">
                      <div className="input-formcontact">
                        <input
                          type="text"
                          id="nombres"
                          placeholder={`${t("forms.fullName")}*`}
                          {...register("nombres", { required: true })}
                        />
                        {errors.nombres && (
                          <span>{t("forms.enterFullName")}</span>
                        )}
                      </div>
                      <div className="input-formcontact">
                        <input
                          type="tel"
                          id="telefono"
                          placeholder={`${t("forms.phone")}*`}
                          {...register("telefono", {
                            required: true,
                            pattern: /^[0-9]+$/,
                          })}
                        />
                        {errors.telefono && (
                          <span>{t("forms.requiredField")}</span>
                        )}
                      </div>
                      <div className="input-formcontact">
                        <input
                          type="email"
                          id="email"
                          placeholder={`${t("forms.email")}*`}
                          {...register("email", {
                            required: true,
                            pattern: /^\S+@\S+$/i,
                          })}
                        />
                        {errors.email && (
                          <span>{t("forms.enterValidEmail")}</span>
                        )}
                      </div>

                      <div className="input-inlines">
                        <div>
                          <label htmlFor="">
                            <p className="form-label">{`${t(
                              "forms.eventDate"
                            )}*`}</p>
                            <input
                              type="date"
                              id="fecha"
                              placeholder="date"
                              min={getNextDay()}
                              {...register("fecha", {
                                required: true,
                              })}
                            />
                            {errors.fecha && (
                              <span>{t("forms.requiredField")}</span>
                            )}
                          </label>
                        </div>

                        <div>
                          <p className="form-label">{`${t(
                            "forms.eventDuration"
                          )}*`}</p>
                          <div className="input-inlines input-inlines-hours">
                            <select
                              id="hora_inicio"
                              {...register("hora_inicio", {
                                required: true,
                              })}
                            >
                              <option value="">{`${t(
                                "forms.startHour"
                              )}`}</option>
                              {hoursArray.map((hour: string) => (
                                <option key={hour} value={hour}>
                                  {hour}
                                </option>
                              ))}
                            </select>
                            {errors.hora_inicio && (
                              <span>{t("forms.requiredField")}</span>
                            )}
                            <select
                              id="hora_fin"
                              {...register("hora_fin", {
                                required: true,
                              })}
                            >
                              <option value="">{`${t(
                                "forms.endHour"
                              )}`}</option>
                              {hoursArray.map((hour: string) => (
                                <option key={hour} value={hour}>
                                  {hour}
                                </option>
                              ))}
                            </select>
                            {errors.hora_fin && (
                              <span>{t("forms.requiredField")}</span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="select-form">
                        <p className="form-label">{`${t(
                          "forms.socialEventType"
                        )}*`}</p>
                        <select
                          id="tipo_evento_social_id"
                          {...register("tipo_evento_social_id", {
                            required: true,
                          })}
                        >
                          <option value="">{`${t(
                            "forms.socialEventType"
                          )}*`}</option>
                          {tipo_eventos_social.map(
                            (option: { id: string; nombre: string }) => (
                              <option key={option.id} value={option.id}>
                                {option.nombre}
                              </option>
                            )
                          )}
                        </select>
                        {errors.tipo_evento_social_id && (
                          <span>{t("forms.requiredField")}</span>
                        )}
                      </div>

                      <div className="input-formcontact">
                        <label htmlFor="capacidad">
                          <p className="form-label">{`${t(
                            "forms.capacity"
                          )}*`}</p>
                          <input
                            type="number"
                            id="numero_personas"
                            min="0"
                            max="999"
                            placeholder={t("forms.capacity")}
                            {...register("numero_personas", {
                              required: true,
                            })}
                          />
                          {errors.numero_personas && (
                            <span>{t("forms.requiredField")}</span>
                          )}
                        </label>
                      </div>

                      <div className="select-form">
                        <label htmlFor="destino">
                          <p className="form-label">{`${t(
                            "forms.selectDestinationforInterest"
                          )}*`}</p>
                        </label>
                        <select
                          id="dst_id"
                          {...register("dst_id", { required: true })}
                        >
                          <option value="">
                            {t("forms.selectDestinationOption")}
                          </option>
                          {destinos.map(
                            (opcion: { id: string; nombre: string }) => (
                              <option key={opcion.id} value={opcion.id}>
                                {opcion.nombre}
                              </option>
                            )
                          )}
                        </select>
                        {errors.dst_id && (
                          <span>{t("forms.requiredField")}</span>
                        )}
                      </div>
                    </div>
                  )}

                  <Controller
                    name="terminos_condiciones"
                    control={control}
                    rules={{ required: t("forms.requiredField") }}
                    render={({ field: { onChange, value, ...field } }) => (
                      <div className="checkbox__form checkbox__form__terms">
                        <label>
                          <input
                            {...field}
                            type="checkbox"
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                          <p>
                            {t("forms.acceptTermsPart1")}{" "}
                            <a
                              href={`/${i18n.language}/terminos-y-condiciones`}
                              className="terms-url"
                              target="_blank"
                            >
                              {t("forms.acceptTermsLink")}
                            </a>{" "}
                            {t("forms.acceptTermsPart2")}*
                          </p>
                        </label>

                        <p className="errors">
                          {errors.terminos_condiciones && (
                            <>{errors.terminos_condiciones.message}</>
                          )}
                        </p>
                      </div>
                    )}
                  />

                  <Controller
                    name="politicas_datos_personales"
                    control={control}
                    rules={{ required: t("forms.requiredField") }}
                    render={({ field: { onChange, value, ...field } }) => (
                      <div className="checkbox__form checkbox__form__terms">
                        <label>
                          <input
                            {...field}
                            type="checkbox"
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                          <p>
                            {t("forms.acceptPrivacy")}{" "}
                            <a
                              href={`/${i18n.language}/politica-de-proteccion-de-datos-personales`}
                              className="terms-url"
                              target="_blank"
                            >
                              {t("forms.acceptPrivacyLink")}.
                            </a>
                            *
                          </p>
                        </label>

                        <p className="errors">
                          {errors.politicas_datos_personales && (
                            <>{errors.politicas_datos_personales.message}</>
                          )}
                        </p>
                      </div>
                    )}
                  />

                  <Controller
                    name="publicidad_y_promociones"
                    control={control}
                    rules={{}}
                    render={({ field: { onChange, value, ...field } }) => (
                      <div className="checkbox__form checkbox__form__terms">
                        <label>
                          <input
                            {...field}
                            type="checkbox"
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                          />
                          <p>{`${t("forms.sendAds")}`}</p>
                        </label>

                        <p className="errors">
                          {errors.publicidad_y_promociones && (
                            <>{errors.publicidad_y_promociones.message}</>
                          )}
                        </p>
                      </div>
                    )}
                  />

                  <button
                    type="submit"
                    disabled={!isValid || !watchCheckbox1 || !watchCheckbox2}
                  >
                    {isLoading ? t("forms.sending") : t("forms.send")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="eventspage">
        <div className="eventspage__container">
          <div className="eventspage__container--detail">
            <div className="agenciascelebra">
              <div className="agenciascelebra__text">
                <Text
                  text={t("events.eventosTreintaYdos")}
                  type="subtitle"
                  className="subtitle"
                />
              </div>
              <div className="agenciascelebra__content">
                {data.map((item, index) => (
                  <div className="agenciascelebra__content--icon" key={index}>
                    <div className="bg-icon">
                      {/* <Icon
                    name={item.icon}
                    list='items'
                    width={100}
                    height={100}
                  /> */}
                      <img src={item.img} alt={item.alt} />
                    </div>
                    <div>
                      <Text
                        text={item.text}
                        type="paragraph"
                        className="paragraph"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="eventspage__container--contact">
            <div className="agenciasestilos">
              <div className="agenciasestilos__text">
                <Text
                  text={t("events.eventosCuarenta")}
                  type="subtitle"
                  className="subtitle"
                />
              </div>
              <div className="agenciasestilos__content">
                <div className="column">
                  <Text
                    text={t("events.eventosCuarentaYuno")}
                    type="subtitle2"
                    className="subtitlecolumn"
                  />
                  <div className="column__data">
                    <Text text={"+65"} type="paragraph" className="number" />
                    <Text
                      text={"salas en 10 destinos"}
                      type="paragraph"
                      className="text"
                    />
                  </div>
                  <Text
                    text={t("events.eventosCuarentaYdos")}
                    type="paragraph"
                    className="paragraph"
                  />
                </div>
                <div className="w30">
                  <Icon
                    name="right"
                    list="arrows"
                    width={24}
                    height={24}
                    className="icon-rotate"
                  />
                </div>
                <div className="column">
                  <Text
                    text={t("events.eventosCuarentaYtres")}
                    type="subtitle2"
                    className="subtitlecolumn"
                  />
                  <div className="card">
                    {cardData.map((card, index) => (
                      <div key={index} className="card__content">
                        <div className="image">
                          <img src={card.image} alt={card.text} />
                        </div>
                        <div className="text">
                          <Text text={card.text} type="paragraph" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w30">
                  <Icon
                    name="right"
                    list="arrows"
                    width={24}
                    height={24}
                    className="icon-rotate"
                  />
                </div>
                <div className="column">
                  <Text
                    text={t("events.eventosCuarentaYcuatro")}
                    type="subtitle2"
                    className="subtitlecolumn"
                  />
                  <div className="lista">
                    {equipos.map((item, index) => (
                      <ul key={index}>
                        <li>{item.name}</li>
                      </ul>
                    ))}
                  </div>
                </div>
                <div className="w30">
                  <Icon
                    name="right"
                    list="arrows"
                    width={24}
                    height={24}
                    className="icon-rotate"
                  />
                </div>
                <div className="column">
                  <Text
                    text={t("events.eventosCincuentaYuno")}
                    type="subtitle2"
                    className="subtitlecolumn"
                  />
                  <div className="lista">
                    {alimentacion.map((item, index) => (
                      <ul key={index}>
                        <li>{item.name}</li>
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="agenciaseventos">
              <div className="agenciaseventos__text">
                <Text
                  text={t("events.eventosSesenta")}
                  type="subtitle"
                  className="subtitle"
                />
                <Text
                  text={t("events.eventosSesentaYuno")}
                  type="paragraph"
                  className="paragraph"
                />
                <Button
                  type="gold"
                  onClick={() => {
                    window.location.href = "mailto:eventos@casa-andina.com";
                  }}
                >
                  {t("events.eventosSesentaYdos")}
                </Button>
              </div>
              <div className="agenciaseventos__img">
                <figure>
                  <img src={Salon} alt="Trip" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
