import { Icon, Text } from "components/atoms";
import { Animations } from "components/molecules";

const PromotionsLoader = ({ data, idFilter }: any) => {
  return (
    <>
      <div className="promotionsyqp">
        <div className="promotionsyqp__container">
          <div className="promotionsyqp__container--titulo">
            <div className="skeleton">
              <div className="skeleton__container"></div>
            </div>
          </div>
          <div className="promotionsyqp__container--icon">
            <Icon name="rightcopy" list="arrows" width={26} height={45} />
          </div>
          <div className="codigo">
            <div className="skeleton">
              <div className="skeleton__container"></div>
            </div>
            <div className="skeleton">
              <div className="skeleton__container"></div>
            </div>
            <div className="skeleton">
              <div className="skeleton__container"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="promotionscard padding__general">
        <div
          className="promotionscard__description"
          style={{
            paddingTop: `20px`,
            paddingBottom: `20px`,
            gridGap: "48px 40px",
          }}
        >
          {[1, 2, 3].map((item, index) => (
            <div className="betterprices__description--card">
              <div className="betterprices__description--card--slider">
                <Animations type="skeletonContent">
                  <div style={{ height: 216, width: "100%" }}></div>
                </Animations>
              </div>
              <div className="betterprices__description--card--container">
                <div className="cardheaderr">
                  <Animations type="skeletonContent">
                    <Text
                      style={{
                        width: 60,
                        height: 20,
                        marginRight: 5,
                      }}
                      type="subtitle2"
                      className="title"
                    />
                  </Animations>
                </div>
                <div className="betterprices__description--card--content">
                  <div className="cardprices">
                    <div className="cardprices__services">
                      <div className="cardprices__services--better">
                        <div className="estilos">
                          <p
                            style={{
                              color: "#7E7E7E",
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                    <div className="cardprices__card">
                      <div>
                        <Animations type="skeletonContent">
                          <p
                            className="ttcardprices"
                            style={{
                              marginBottom: "20px",
                            }}
                          >
                            <span style={{ width: 50, height: 10 }}></span>
                            <span></span>
                          </p>
                        </Animations>
                      </div>
                      <div className="carddescuento">
                        <Animations type="skeletonContent">
                          <Text
                            style={{ width: 35, height: 40 }}
                            className="sbcardprices"
                            type="paragraph"
                          />
                        </Animations>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    <div className="date">
                      <div>
                        <Animations type="skeletonContent">
                          <Text style={{ width: 120, height: 20 }} />
                        </Animations>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PromotionsLoader;
