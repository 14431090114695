import { BannerSlider } from 'components/organisms'
import PromotionsCard from 'components/organisms/promotions-card'
import PromotionsCopy from 'components/organisms/promotions-copy'
import PromotionsForm from 'components/organisms/promotions-form'
import { useTranslation } from "react-i18next";

const PromotionsRelax = ({ data, idFilter }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <PromotionsCopy data={data} />

      {data.is_formulario ? (
        <PromotionsForm data={data?.imagenes_form} />
      ) : (
        <PromotionsCard
          title={`${t('promotions.takeBeneffitOfOurDiscounts')}`}
          data={data.hoteles}
          type='campaign2'
          idFilter={idFilter}
        />
      )}
    </>
  )
}

export default PromotionsRelax
