import { IconText, Text } from "components/atoms";
import { useIntl } from "react-intl";
import "./styles.scss";
import { getContactInformation } from "actions/contact/services";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/useAppSelector";
import Animations from "components/molecules/animations";

interface IContactInformation {
  correo: string;
  direccion: string;
  telefono?: string;
  titulo: string;
  celular?: string;
  google_maps_link?: string;
}

// Componente de descripción de la tarjeta
const ContactList = () => {
  const { locale } = useIntl();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchName, setSearchName] = useState("");
  const { loading, language, data } = useAppSelector((state) => ({
    loading: state.language.loading,
    language: state.config.language,
    data: state.language.dataContactInformation,
  }));

  const handleClickSearchButton = () => {
    const localParams = {
      search_name: searchName,
    };
    getContactInformation({ language, navigate, params: localParams });
  };

  useEffect(() => {
    handleClickSearchButton();
  }, [language]);

  return (
    <div className="contacto-list">
      <div className="search-contact-container">
        <Text
          text={`${t("forms.directorySectionName")}`}
          type="subtitle"
          className="titulo-contacto"
        />
        <div className="d-flex">
          <input
            className="search-contact-container--input"
            type="text"
            placeholder={`${t("forms.searchContactName")}`}
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
          <button
            onClick={handleClickSearchButton}
            className="search-contact-container--button"
          >{`${t("reservationAgents.search")}`}</button>
        </div>
      </div>
      {loading && (
        <>
          {Array.from({ length: 6 }).map((_, index) => (
            <div className="card-contacto" key={index}>
              <div className="card-contacto--columna-izquierda">
                <Animations type="skeleton">
                  <Text
                    text={"Lore"}
                    type="paragraph"
                    style={{ color: "transparent" }}
                  />
                </Animations>
                <br />
                <Animations type="skeleton-200px">
                  <Text
                    text={"Lorem ipsum, dolor sit amet consectetur adipisic"}
                    type="paragraph"
                    style={{ color: "transparent" }}
                  />
                </Animations>
              </div>
              <div className="card-contacto--columna-derecha">
                <Animations type="skeleton-200px">
                  <Text
                    text={"Lorem ipsum, dolor sit amet consectetur adipisic"}
                    type="paragraph"
                    style={{ color: "transparent" }}
                  />
                </Animations>
                <br />
                <Animations type="skeleton-200px">
                  <Text
                    text={"Lorem ipsum, dolor sit amet consectetur adipisic"}
                    type="paragraph"
                    style={{ color: "transparent" }}
                  />
                </Animations>
                <br />
                <Animations type="skeleton-200px">
                  <Text
                    text={"Lorem ipsum, dolor sit amet consectetur adipisic"}
                    type="paragraph"
                    style={{ color: "transparent" }}
                  />
                </Animations>
              </div>
            </div>
          ))}
        </>
      )}

      {!loading && data?.length == 0 && (
        <p className="hotels-not-found-message">{`${t(
          "forms.contactListNotFound"
        )}`}</p>
      )}

      {!loading &&
        data?.map(
          ({
            correo,
            direccion,
            telefono,
            titulo,
            celular,
            google_maps_link,
          }: IContactInformation) => (
            <div className="card-contacto" key={titulo}>
              <div className="card-contacto--columna-izquierda">
                <IconText
                  nameIcon="house"
                  listIcon={"items"}
                  color="#008EAA"
                  type="paragraph"
                  w={24}
                  h={24}
                />
                <Text text={titulo} type="subtitle" />
              </div>
              <div className="card-contacto--columna-derecha">
                {direccion && (
                  <span className="card-contacto--columna-derecha--icono-texto">
                    <IconText
                      nameIcon="map"
                      listIcon={"buttons"}
                      color="#D69C4F"
                      type="left"
                      className="icon-left"
                      w={22}
                      h={22}
                    />
                    <a href={google_maps_link} target="_blank">
                      {direccion}
                    </a>
                  </span>
                )}
                {correo && (
                  <span className="card-contacto--columna-derecha--icono-texto">
                    <IconText
                      nameIcon="email"
                      listIcon={"items"}
                      color="#D69C4F"
                      type="left"
                      className="icon-left"
                      w={22}
                      h={22}
                    />
                    <a href={`mailto:${correo}`}>{correo}</a>
                  </span>
                )}
                {telefono && celular ? (
                  <span className="card-contacto--columna-derecha--icono-texto">
                    <IconText
                      nameIcon="phone"
                      listIcon={"items"}
                      color="#D69C4F"
                      type="left"
                      className="icon-left"
                      w={22}
                      h={22}
                    />
                    <a href={`tel:${telefono}`}>{telefono}</a>
                    <a href={`tel:${celular}`} className="border-left">
                      {celular}
                    </a>
                  </span>
                ) : (
                  (telefono || celular) && (
                    <span className="card-contacto--columna-derecha--icono-texto">
                      <IconText
                        nameIcon="phone"
                        listIcon={"items"}
                        color="#D69C4F"
                        type="left"
                        className="icon-left"
                        w={22}
                        h={22}
                      />
                      <a href={`tel:${telefono || celular}`}>
                        {telefono || celular}
                      </a>
                    </span>
                  )
                )}
              </div>
            </div>
          )
        )}
    </div>
  );
};

export default ContactList;
