import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "sonner";
import { Text } from "components/atoms";
import { Modal } from "components/molecules";
import { BannerSlider } from "components/organisms";
import { REACT_APP_ENDPOINT_CA } from "typings/env";
import { redirectUrlExternal, logSentryErrorResponse } from "utils/function";
import useRecaptcha from "hooks/useRecaptcha";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { useAppSelector } from "hooks/useAppSelector";

interface IDataProps {
  paquete: string;
  nombres: string;
  telefono: string;
  email: string;
  politica_privacidad: boolean;
}

const PromotionsPackages = ({ data }: any) => {
  const { t } = useTranslation();
  const { getRecaptchaToken } = useRecaptcha();
  const loading = useAppSelector(
    (state) => state.language.loadingPromotionSubPages
  );

  const [activeLink, setActiveLink] = useState<string>(
    data.paquetes_exclusivos[0].titulo
  );
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const sectionRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  const [selectedPackageId, setSelectedPackageId] = useState<string>("");
  const [selectedPackageTitle, setSelectedPackageTitle] = useState<string>("");
  const [tempPackageId, setTempPackageId] = useState<string>("");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const toogleModal = (packageId: string, packageTitle: string) => {
    if (!modal) {
      setSelectedPackageId(packageId);
      setSelectedPackageTitle(packageTitle);
      setTempPackageId(packageId);
    }
    setModal(!modal);
  };

  const redirectSection = (title: string) => () => {
    const section = sectionRefs.current[title];
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
      setActiveLink(title);
    }
  };

  const onSubmit = async (formData: any) => {
    const token = await getRecaptchaToken();

    if (!token) {
      toast.error("Error al obtener el token de reCAPTCHA", {
        position: "top-right",
      });
      return;
    }

    try {
      const { nombres, telefono, email } = formData as IDataProps;

      setIsLoading(true);
      const response = await fetch(
        `${REACT_APP_ENDPOINT_CA}/form/paquetes_exclusivos`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "g-recaptcha-response": token,
          },
          body: JSON.stringify({
            paquete: Number(tempPackageId),
            nombres,
            telefono,
            email,
            politica_privacidad: true ? 1 : 0,
          }),
        }
      );
      if (response.ok) {
        const data = await response.json();

        toast.success(data.message, { position: "top-right" });
        setSelectedPackageId(tempPackageId);
        reset();
        setIsLoading(false);
        setModal(false);
      } else {
        logSentryErrorResponse(response, "Form - Paquetes Exclusivos Form");
        console.error(response);
        toast.error(t("forms.errorSendingData"), { position: "top-right" });
      }
    } catch (error) {
      // alert('Ocurrió un error inesperado');
      // Sentry.captureException(error); // Captura el error en Sentry
      // console.error(error);
      toast.error(t("forms.errorSendingData"), { position: "top-right" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      for (const pkg of data.paquetes_exclusivos) {
        const ref = sectionRefs.current[pkg.titulo];
        if (ref) {
          const { offsetTop, offsetHeight } = ref;
          if (
            scrollPosition >= offsetTop &&
            scrollPosition < offsetTop + offsetHeight
          ) {
            setActiveLink(pkg.titulo);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [data.paquetes_exclusivos]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!modal) {
      reset();
    }
  }, [modal, reset]);

  useEffect(() => {
    if (modal) {
      setTempPackageId(selectedPackageId);
    }
  }, [modal, selectedPackageId]);

  return (
    <>
      {loading ? (
        <></>
        // <section className={"packagepage2"}>
        //   <div className={"packagepage2__container"}>
        //     <figure className="packagepage__container--left">
        //       <div className="skeleton" style={{ height: "100%" }}>
        //         <div className="skeleton__container"></div>
        //       </div>
        //     </figure>
        //     <div className="packagepage__container--right">
        //       <h2>
        //         <div className="skeleton">
        //           <div className="skeleton__container"></div>
        //         </div>
        //       </h2>
        //       <div className="packagepage__container--list">
        //         <ul style={{ listStyleType: "none" }}>
        //           <li>
        //             <div className="skeleton">
        //               <div className="skeleton__container"></div>
        //             </div>
        //           </li>
        //           <li>
        //             <div className="skeleton">
        //               <div className="skeleton__container"></div>
        //             </div>
        //           </li>
        //           <li>
        //             <div className="skeleton">
        //               <div className="skeleton__container"></div>
        //             </div>
        //           </li>
        //         </ul>
        //       </div>
        //     </div>
        //   </div>
        // </section>
      ) : (
        <>
          <section className="packagetab">
            <div className="packagetab__container">
              <Text
                html={{
                  __html: data.descripcion,
                }}
                className="packagetab__container--title"
              />
              <div className="packagetab__container--tabs">
                {data.paquetes_exclusivos.map((pkg: any) => (
                  <div
                    key={pkg.id}
                    onClick={redirectSection(pkg.titulo)}
                    className={`packagetab__container--link ${
                      activeLink === pkg.titulo ? "active" : ""
                    }`}
                  >
                    {pkg.titulo}
                  </div>
                ))}
              </div>
            </div>
          </section>

          {data.paquetes_exclusivos.map((pkg: any, index: number) => (
            <section
              key={pkg.id}
              className={index % 2 === 0 ? "packagepage" : "packagepage2"}
              ref={(el) => (sectionRefs.current[pkg.titulo] = el)}
            >
              <div
                className={
                  index % 2 === 0
                    ? "packagepage__container"
                    : "packagepage2__container"
                }
              >
                {index % 2 === 0 ? (
                  <>
                    <figure className="packagepage__container--left">
                      <img src={pkg.imagen.path} alt={pkg.imagen.alt} />
                    </figure>
                    <div className="packagepage__container--right">
                      <h2>{pkg.titulo}</h2>
                      <div className="packagepage__container--list">
                        <ul>
                          {pkg.servicios
                            .filter((service: any) => service.id % 2 !== 0)
                            .map((service: any) => (
                              <li key={service.id}>{service.titulo}</li>
                            ))}
                        </ul>
                        <ul>
                          {pkg.servicios
                            .filter((service: any) => service.id % 2 === 0)
                            .map((service: any) => (
                              <li key={service.id}>{service.titulo}</li>
                            ))}
                        </ul>
                      </div>
                      <p className="packagepage__container--terms">
                        {t("promotions.tyc")},{" "}
                        <span
                          onClick={() =>
                            redirectUrlExternal(pkg.terminos_condiciones)
                          }
                        >
                          aquí
                        </span>
                      </p>
                      <div className="packagepage__container--details">
                        <div className="prices">
                          <p className="prices__text">
                            {pkg.texto_encima_precio}
                          </p>
                          <p className="prices__price">
                            {pkg.precio.simbolo} {pkg.precio.precio}
                          </p>
                          <p className="prices__taxes">
                            {pkg.condicion_abajo_precio}
                          </p>
                        </div>
                        <div
                          className="contact"
                          onClick={() => toogleModal(pkg.id, pkg.titulo)}
                        >
                          <button>Contáctanos</button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="packagepage2__container--left">
                      <h2>{pkg.titulo}</h2>
                      <div className="packagepage2__container--list">
                        <ul>
                          {pkg.servicios
                            .filter((service: any) => service.id % 2 !== 0)
                            .map((service: any) => (
                              <li key={service.id}>{service.titulo}</li>
                            ))}
                        </ul>
                        <ul>
                          {pkg.servicios
                            .filter((service: any) => service.id % 2 === 0)
                            .map((service: any) => (
                              <li key={service.id}>{service.titulo}</li>
                            ))}
                        </ul>
                      </div>
                      <p className="packagepage2__container--terms">
                        {t("promotions.tyc")},{" "}
                        <span
                          onClick={() =>
                            redirectUrlExternal(pkg.terminos_condiciones)
                          }
                        >
                          {t("promotions.here")}
                        </span>
                      </p>
                      <div className="packagepage2__container--details">
                        <div className="prices">
                          <p className="prices__text">
                            {pkg.texto_encima_precio}
                          </p>
                          <p className="prices__price">
                            {pkg.precio.simbolo} {pkg.precio.precio}
                          </p>
                          <p className="prices__taxes">
                            {pkg.condicion_abajo_precio}
                          </p>
                        </div>
                        <div
                          className="contact"
                          onClick={() => toogleModal(pkg.id, pkg.titulo)}
                        >
                          <button>Contáctanos</button>
                        </div>
                      </div>
                    </div>
                    <figure className="packagepage2__container--right">
                      <img src={pkg.imagen.path} alt={pkg.imagen.alt} />
                    </figure>
                  </>
                )}
              </div>
            </section>
          ))}
        </>
      )}

      <Modal
        isTitle={false}
        showModal={modal}
        onlyClose={false}
        close={toogleModal}
        changeWidth={true}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="forminputpackge">
          <Text
            className="paragraph"
            text="Selecciona el paquete de tu interés:"
            type="paragraph"
          />
          <div>
            <select
              id="paquete"
              {...register("paquete", {
                required: true,
              })}
              value={tempPackageId}
              onChange={(e) => {
                setTempPackageId(e.target.value);
                const selectedPkg = data.paquetes_exclusivos.find(
                  (pkg: any) => pkg.id === e.target.value
                );
                if (selectedPkg) {
                  setSelectedPackageTitle(selectedPkg.titulo);
                }
              }}
            >
              <option value="">Selecciona tu paquete</option>
              {data.paquetes_exclusivos.map((pkg: any) => (
                <option key={pkg.id} value={pkg.id}>
                  {pkg.titulo}
                </option>
              ))}
            </select>
            {errors.paquete && <span>{t("forms.requiredField")}</span>}
          </div>
          <div>
            <div className="input-form">
              <input
                type="text"
                id="nombres"
                placeholder={t("forms.fullName")}
                {...register("nombres", { required: true })}
              />
              {errors.nombres && <span>{t("forms.requiredField")}</span>}
            </div>

            <div className="input-form">
              <input
                type="text"
                id="telefono"
                placeholder={t("forms.phone")}
                {...register("telefono", { required: true })}
              />
              {errors.telefono && <span>{t("forms.requiredField")}</span>}
            </div>

            <div className="input-form">
              <input
                type="text"
                id="email"
                placeholder="Correo electrónico"
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                })}
              />
              {errors.email && <span>{t("forms.requiredField")}</span>}
            </div>

            <Controller
              name="politica_privacidad"
              control={control}
              rules={{ required: t("forms.requiredField") }}
              render={({ field: { onChange, value, ...field } }) => (
                <div className="checkbox__form">
                  <label>
                    <input
                      {...field}
                      type="checkbox"
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                    {`${t("forms.readAcceptAds")}*`}
                  </label>
                </div>
              )}
            />
            <p className="errors">
              {errors.politica_privacidad && (
                <>{errors.politica_privacidad.message}</>
              )}
            </p>

            <button type="submit" disabled={!isValid} className="btnagencies">
              {isLoading ? "Validando..." : "Enviar"}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default PromotionsPackages;
