import { Button, IconText, Text } from "components/atoms";
import "./styles.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks/useAppSelector";
import { getHotelesDestinations } from "actions/hotel/services";
import { Tooltip } from "react-tooltip";
import { Animations } from "components/molecules";
import { dataBanner } from "pages/landings/momentos-casa-andina/data";

interface IBannerTiraHome {
  promoBannerTira?: any;
}

const BannerTiraHome = ({ promoBannerTira }: IBannerTiraHome) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const bannerTiraData = {
    place: "casa-andina-select-zorritos-tumbes",
    image: "https://s3.us-east-1.amazonaws.com/ca-webdev/media/jaccuzi-el-golf.png",
    descuento_life: 5,
    descripcion: t("bannerTiraHome.description"),
    titulo: t("bannerTiraHome.title"),
  }

  const {
    language,
    coin,
    loading,
    data
  } = useAppSelector((state) => ({
    language: state.config.language,
    loading: state.language.loading,
    coin: state.config.coin,
    data: state.language.dataHotelesDestination,
  }));

  useEffect(() => {
    getHotelesDestinations({ place: bannerTiraData.place, language, coin, navigate });
  }, [language, coin, navigate]);

  return (
    <>
      {data && (
        <>
          <div className="bannertirahome" style={{ backgroundImage: `url(${bannerTiraData.image})` }}>
            <div className="bannertirahome__overlay"></div>
            <div className="bannertirahome__container">
              <div className="bannertirahome__container--content--left">
                <p>{bannerTiraData.descripcion}</p>

                <Button type="icongold" onClick={() => { navigate(data?.slug) }}>
                  {t("bannerTiraHome.learnMore")}
                </Button>
              </div>
              <div className="bannertirahome__container--content--right">
                <div className="bannertirahome__container--content--right--content">
                  <Text
                    text={bannerTiraData.titulo}
                    type="paragraph"
                    className="title"
                  />
                  <p className="night">
                    {t("betterPrices.nightFrom")}{" "}
                  </p>
                  <p className="price">
                    {data?.precio?.simbolo}
                    {data?.precio?.precio}
                  </p>
                  <div className="igv-tooltip">
                    <IconText
                      text={`+${data?.igv?.simbolo} ${data?.igv?.precio} ${data?.texto_tooltip_igv_card}`}
                      nameIcon="question-card"
                      listIcon="items"
                      w={16}
                      h={16}
                      type="right"
                      className="igv"
                      className2={`tooltip-price-${data?.id}`}
                      color="white"
                    />
                    <Tooltip
                      clickable={true}
                      anchorSelect={`.tooltip-price-${data?.id}`}
                      place="top-end"
                      opacity={1}
                      style={{
                        backgroundColor: "white",
                        color: "#222",
                        boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                        fontFamily: "Quicksand",
                        fontWeight: "300",
                        width: "200px",
                        fontSize: "10px",
                        zIndex: 10,
                      }}
                    >
                      {data?.texto_tooltip_igv}
                    </Tooltip>
                  </div>
                  <Text
                    type="paragraph"
                    text={t("bannerTiraPromocion.lifeDiscount", { discount: bannerTiraData.descuento_life })}
                    className="texto-descuento-life"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="bannertirahome bannertirahome--mobile" style={{ backgroundImage: `url(${bannerTiraData.image})` }}>
            <div className="bannertirahome__overlay"></div>
            <div className="bannertirahome__container">
              <div className="bannertirahome__container--content--right">
                <div className="bannertirahome__container--content--right--content">
                  <Text
                    text={bannerTiraData.titulo}
                    type="paragraph"
                    className="title"
                  />
                  <p className="night">
                    {t("betterPrices.nightFrom")}{" "}
                  </p>
                  <p className="price">
                    {data?.precio?.simbolo}
                    {data?.precio?.precio}
                  </p>
                  <div className="igv-tooltip">
                    <IconText
                      text={`+${data?.igv?.simbolo} ${data?.igv?.precio} ${data?.texto_tooltip_igv_card}`}
                      nameIcon="question-card"
                      listIcon="items"
                      w={16}
                      h={16}
                      type="right"
                      className="igv"
                      className2={`tooltip-price-${data?.id}`}
                      color="white"
                    />
                    <Tooltip
                      clickable={true}
                      anchorSelect={`.tooltip-price-${data?.id}`}
                      place="top-end"
                      opacity={1}
                      style={{
                        backgroundColor: "white",
                        color: "#222",
                        boxShadow: "rgba(0, 0, 0, 0.12) 0px 5px 15px",
                        fontFamily: "Quicksand",
                        fontWeight: "300",
                        width: "200px",
                        fontSize: "10px",
                        zIndex: 10,
                      }}
                    >
                      {data?.texto_tooltip_igv}
                    </Tooltip>
                  </div>
                  <Text
                    type="paragraph"
                    text={t("bannerTiraPromocion.lifeDiscount", { discount: bannerTiraData.descuento_life })}
                    className="texto-descuento-life"
                  />
                </div>
              </div>
              <div className="bannertirahome__container--content--left">
                <p>{bannerTiraData.descripcion}</p>

                <Button type="icongold" onClick={() => { navigate(data?.slug) }}>
                  {t("bannerTiraHome.learnMore")}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      {!data && (
        <div className="bannertirahome bannertirahome--mobile bannertirahome--loading">
          <div className="bannertirahome__container">
            <div className="bannertirahome__container--content--left" style={{ width: "100%" }}>
              <Animations type="skeletonContent">
                <Text
                  style={{ width: "100%", height: 80 }}
                  type="paragraph"
                  className="content__description--subtitle"
                />
              </Animations>
              <Animations type="skeletonContent">
                <Text
                  style={{ width: 60, height: 25 }}
                  type="paragraph"
                  className="content__description--subtitle"
                />
              </Animations>
            </div>
            <div className="bannertirahome__container--content--right" style={{ width: "100%" }}>
              <div className="bannertirahome__container--content--right--content" style={{ width: "100%" }}>

                <Animations type="skeletonContent">
                  <Text
                    style={{ width: "100%", height: 25, marginBottom: 10 }}
                    type="paragraph"
                    className="content__description--subtitle"
                  />
                </Animations>

                <Animations type="skeletonContent">
                  <Text
                    style={{ width: "100%", height: 25, marginBottom: 10 }}
                    type="paragraph"
                    className="content__description--subtitle"
                  />
                </Animations>

                <Animations type="skeletonContent">
                  <Text
                    style={{ width: "100%", height: 25, marginBottom: 10 }}
                    type="paragraph"
                    className="content__description--subtitle"
                  />
                </Animations>

                <Animations type="skeletonContent">
                  <Text
                    style={{ width: "100%", height: 25, marginBottom: 10 }}
                    type="paragraph"
                    className="content__description--subtitle"
                  />
                </Animations>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerTiraHome;
