import { Icon, IconText, Text } from "components/atoms";
import { IBannersHeader } from "./props";
import { Banner } from "components/molecules";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const BannersHeader = ({
  img,
  titulo,
  subtitulo,
  description,
  data,
  bannerRef,
  valuationsRef,
}: IBannersHeader) => {
  const { t } = useTranslation();

  const scrollToSectionWithMargin = (ref: any) => {
    if (ref.current) {
      const marginTop = 126;
      const topOffset = ref.current.offsetTop + marginTop;

      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <Banner
      bgImg={img}
      className="bannerheaders"
      gradiente
      bannerRef={bannerRef}
    >
      <div className="bannerheaders__overlay"></div>
      <div className="bannerheaders__container">
        <div className="bannerheaders__container--text">
          {data.h1_alternativo && (
            <Text text={data.h1_alternativo} type="title" className="h1_alternativo" />
          )}

          <div>
            <Text
              text={`${subtitulo} ${titulo}`}
              type={data.h1_alternativo ? "subtitle" : "title"}
              className="title"
            />
          </div>

          <div className="renderdescription">
            <div className="iconrender">
              {/* {data?.icono_atributo_destacado_banner !== null ? (
                <img
                  src={data?.icono_atributo_destacado_banner?.path}
                  alt="icon"
                  height={24}
                  width={24}
                />
              ) : (
                <div>
                  <Icon name="check" list="items" width={24} height={24} />
                </div>
              )}
              <p className="iconrender__description">{description}</p> */}
            </div>
            <div className="isdesktop">
              {data?.ota.calificacion !== null && (
                <div className="renderdescription__description">
                  <p className="renderdescription__description--title">
                    {t("hotelCity.rating")}
                    <span>{data.ota.calificacion_descripción}</span>
                  </p>
                  <div className="renderdescription__description--review">
                    <Text
                      text={data?.ota?.calificacion}
                      type="paragraph"
                      className="ota"
                    />
                    <IconText
                      text={
                        data?.numero_reseñas?.length > 1
                          ? t("hotelCity.reviews", {
                              count: data?.numero_reseñas,
                            })
                          : t("hotelCity.review", {
                              count: data?.numero_reseñas,
                            })
                      }
                      nameIcon="star2"
                      listIcon="items"
                      className={`hotelsreview ${valuationsRef ? "link" : ""}`}
                      w={24}
                      h={24}
                      type="zero"
                      onClick={() => scrollToSectionWithMargin(valuationsRef)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Banner>
  );
};

export default BannersHeader;
